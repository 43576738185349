import React from "react";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: "100px",
  height: "26px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: "1px",
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "#74613C",
        opacity: 1
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    width: "50px",
    height: "22px",
    margin: "1px",
    borderRadius: "16px"
  },
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#818181",
    opacity: 1,
    "&::before, &::after": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
    "&::after": {
      content: "'Hide'",
      left: "8px",
    },
    "&::before": {
      content: "'Visible'",
      right: "7px",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#74613C", 
    transform: "translateX(45px)", // !important is unnecessary with styled
  },
}));

export default function ToggleButton({ checked, onChange }) {
  return (
    <StyledSwitch checked={checked} onChange={onChange} name="checkedA" />
  );
}