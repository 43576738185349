import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "./../../../axios/axiosInstance";
import { notify } from "./../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";
import { logger } from "../../../utils/logging";


export const getAnandaProgramListReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_ANANDA_PROGRAMS_LIST}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getAnandaProgramList({data}){
    try{
        let result = yield call(getAnandaProgramListReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_ANANDA_PROGRAMS_LIST_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_ANANDA_PROGRAMS_LIST_FAILURE})
        }
        
    }catch(error){
        logger.log(error)
    }
}



function* ProgramSaga(){
    yield takeEvery(types.GET_ANANDA_PROGRAMS_LIST, getAnandaProgramList)
}

export default ProgramSaga;