import { call, put, takeLatest } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import * as types from "../../constants";
import {logOut} from '../../../utils/helpers/logout'
import apiEndpoints from "../../../axios/api";
import moment from "moment";
import { logger } from "../../../utils/logging";

export const getDaywiseUserScheduleReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.GET_DAYWISE_USER_SCHEDULE_API}?user_id=${data.guestId}&user_program_id=${data.userProgramId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getDaywiseUserSchedule({data}){
    try{
        let result = yield call(getDaywiseUserScheduleReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_DAYWISE_USER_SCHEDULE_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.GET_DAYWISE_USER_SCHEDULE_FALIURE});
        }
    }catch(error){
        yield put({ type: types.GET_DAYWISE_USER_SCHEDULE_FALIURE, message: error.message });
    }
}

export const addSessionNotesReq = async({data})=>{
    const BODY = {
        "user_id": data.guestId,
        "user_session_id": data.sessionId,
        "session_notes": data.content,
        "session_name": data.title,
        "session_date": moment(data.startDate).format('YYYY-MM-DD') ,
        "is_notes_guest_visible": data.isVisible ? 1: 0
      }

    return axiosInstance.put(apiEndpoints.ADD_SESSION_NOTES_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* addSessionNotes({data}){
    try{
        let result = yield call(addSessionNotesReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.ADD_SESSION_NOTES_SUCCESS }); 
            window.history.back();
            notify('success', result.message);
        }else{
            yield put({ type: types.ADD_SESSION_NOTES_FALIURE }); 
        }
    }catch(error){
        yield put({ type: types.ADD_SESSION_NOTES_FALIURE }); 
    }
}

export const viewSessionNotesReq = async({data})=>{   
    return axiosInstance.get(`${apiEndpoints.VIEW_SESSION_NOTES_API}?user_session_id=${data.sessionId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);

            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            }else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* viewSessionNotes({data}){
    try{
        let result = yield call(viewSessionNotesReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.VIEW_SESSION_NOTES_SUCCESS, payload: result.data});  
        }else{
            yield put({ type: types.VIEW_SESSION_NOTES_FALIURE});
        }
    }catch(error){
        yield put({ type: types.VIEW_SESSION_NOTES_FALIURE, message: error.message });
    }
}


export const deleteSessionNoteReq = async({data})=>{
    const BODY = {
        "user_session_id": data.sessionId
      }

    return axiosInstance.delete(apiEndpoints.DELETE_SESSION_NOTES_API, { data: {data: BODY} })
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* deleteSessionNote({data}){
    try{
        let result = yield call(deleteSessionNoteReq, {data})

        if(result.statusCode === 200){
            yield put({ type: types.DELETE_SESSION_NOTES_SUCCESS});
            window.history.back();
            notify('success', result.message);
        }
    }catch(error){
        logger.log(error)
    }
}

function* sessionsSaga(){
    yield takeLatest(types.GET_DAYWISE_USER_SCHEDULE, getDaywiseUserSchedule)
    yield takeLatest(types.ADD_SESSION_NOTES, addSessionNotes)
    yield takeLatest(types.VIEW_SESSION_NOTES, viewSessionNotes)
    yield takeLatest(types.DELETE_SESSION_NOTES, deleteSessionNote)
}

export default sessionsSaga;