import React, { useEffect, useState } from 'react'
import './AddPrelimAssessment.scss';
import {TextFieldFM} from '../../../components/TextField/TextField';
import YesNoComponentFM from '../../../components/YesNoComponent/YesNoComponent'
import { Form, Formik, FastField, FieldArray } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { prelimAssessmentValidation } from '../../../validationSchema/prelimAssessmentSchema'
import { useDispatch, useSelector } from 'react-redux'
import { notify } from '../../../utils/notification'
import { addPreAssessmentData, getHealthCategoriesList } from '../../../redux/actions/GuestDataActions/PrelimAssessmentActions'
import Select from "react-select";
import {intensityOptions, periodOptions} from '../../../const/dropdownOptions'
import { ReactComponent as Add } from '../../../assets/images/prelimAdd.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'
import { CircularProgress } from '@mui/material';
import UploadButton from '../../../components/UploadButton/UploadButton';

function AddPrelimAssessment() {
  const location = useLocation();
  const { guestId, userProgramId, type} = location.state;
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [files, setFiles] = useState({newFiles: [], uploadedFiles:[]})
  const updateLoading = useSelector(state=> state.prelimAssessmentReducer?.updateLoading)
  const healthCategories = useSelector(state=> state.prelimAssessmentReducer?.healthCategories)

  useEffect(()=>{
    dispatch(getHealthCategoriesList())
  }, [])

  return (
    <>
      <div className="add-prelim-assess-container">
        <div className="container h-100">
            <div className="add-prelim-assess-heading">
            Pre Arrival Consultation
            </div>

            <Formik
                initialValues={{ 
                  user_height: '',
                  user_weight: '',
                  objectives: '',
                  is_current_health_complaint: '',
                  health_complaint_keywords: [],
                  is_past_medical_history: '',
                  past_medical_history: [],
                  is_current_medications: '',
                  current_medications:"",
                  is_allergies: '',
                  allergies_keywords: "",
                  family_history: "",
                  diet: "",
                  recommended_stay_duration: "",
                  recommended_program: "",
                  medical_reports_requested_for: "",
                  specific_request_from_guest: ""
              }}
                validateOnMount={true}
                validationSchema={prelimAssessmentValidation}
                onSubmit={async(values,  { resetForm })=>{
                  dispatch(addPreAssessmentData({...values, user_id:guestId, user_program_id: userProgramId, type, files}))
              }}>
                {
                  (formik)=>{
                    return(
                      <Form>
                        <div className="pt-4 pb-5">
                          <div className="add-prelim-assess-content">
                            <div className="d-flex justify-content-between align-items-center p-3">
                              <div className="heading">Fill Guest Data</div>
                              <div>
                                <button className='cancel' type='button' onClick={()=> navigate(-1)}>Cancel</button>
                                <button className="add-btn" type='submit'
                                  data-testid="add-btn" 
                                  onClick={()=> {
                                    if (!formik.isValid)
                                      notify("warning", "Please fill all details")
                                    }
                                  }
                                >
                                  {
                                    updateLoading ?
                                    <CircularProgress size={20} sx={{'color': 'white'}} />
                                    :
                                    'ADD'
                                  }
                                </button>
                              </div>
                            </div>

                            <div className="form">
                              <div className="row">
                                <div className="col">
                                  <div className="data-box">
                                    <div className="title">Height</div>
                                    <div className="value">
                                      <TextFieldFM type="numeric" 
                                        name={"user_height"}
                                        unit="cm" 
                                        placeholder="Height"
                                        testID={"user_height"}
                                      />
                                      <div className="footer">{`${(formik.values.user_height * 0.0328084).toFixed(2)} ft`}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="data-box">
                                    <div className="title">Weight</div>
                                    <div className="value">
                                      <TextFieldFM type="numeric" 
                                        name={"user_weight"}
                                        unit="kg" 
                                        placeholder="Weight"
                                        testID={"user_weight"}
                                      />
                                      <div className="footer">{`${(formik.values.user_weight * 2.20462).toFixed(2)} lb`}</div>
                                    </div>
                                  </div>                   
                                </div>
                              </div>

                              <div className="data-box">
                                <div className="title">
                                  <span>Current Health Complaints <i></i></span>
                                  <YesNoComponentFM name="is_current_health_complaint"
                                    yesTestID= "yes_is_current_health_complaint"
                                    noTestID= "no_is_current_health_complaint"
                                    handleClick={()=>{
                                      formik.setFieldValue("health_complaint_keywords", [])
                                    }}
                                    handleClickYes={()=>{
                                          formik.setFieldValue("health_complaint_keywords", [{
                                            health_category: '',
                                            health_condition: '',
                                            details: '',
                                            intensity: '',
                                            duration: '',
                                          }])
                                      }}
                                  />
                                </div>
                                <div>
                                  {formik.values.is_current_health_complaint==='1' &&
                                    <div className="row m-0 fw-semibold pb-2 border-top border-bottom mb-3" >
                                      <div className="mt-2 col-2">Health Category</div>
                                      <div className="mt-2 col-2">Health Condition</div>
                                      <div className="mt-2 col-3">Details</div>
                                      <div className='mt-2 col-2'>
                                      Intensity
                                      </div>
                                      <div className='mt-2 col-3'>
                                          Duration
                                      </div>
                                  </div>
                                  }
                                  {
                                    formik.values.is_current_health_complaint === '1'
                                    &&
                                    <FieldArray
                                      className='mt-3'
                                      name="health_complaint_keywords"
                                      render={arrayHelpers => (
                                        <div className="p-2">
                                          {formik.values.health_complaint_keywords && formik.values.health_complaint_keywords.length > 0 && (
                                            formik.values.health_complaint_keywords.map((item, index) => (
                                            <div className='row' key={index}>
                                              <div className="data-row col-2">
                                                <div className='date-input'>
                                                  <Select
                                                    className="name-select"
                                                    classNamePrefix="select"
                                                    // isLoading={isLoading}
                                                    isSearchable={true}
                                                    name="color"
                                                    options={healthCategories?.map((item)=> ({
                                                      label: item?.health_category,
                                                      value: item?.health_category,
                                                    })) || []}
                                                    placeholder='Select Category'
                                                    onChange={(e)=>{
                                                      formik.setFieldValue(`health_complaint_keywords.${index}.health_category`, e.value)
                                                      formik.setFieldValue(`health_complaint_keywords.${index}.health_condition`, '')
                                                  }}
                                                    value={formik.values.health_complaint_keywords?.[index]?.health_category ?{
                                                      label: formik.values.health_complaint_keywords?.[index]?.health_category || 'NA',
                                                      value: formik.values.health_complaint_keywords?.[index]?.health_category
                                                    } : null}
                                                  />
                                              </div>
                                              </div>
                                              <div className="data-row col-2">
                                                <div className='date-input'>
                                                    <Select
                                                      className="name-select"
                                                      classNamePrefix="select"
                                                      // isLoading={isLoading}
                                                      isSearchable={true}
                                                      name="color"
                                                      options={
                                                        formik.values.health_complaint_keywords?.[index]?.health_category ?
                                                        healthCategories.find(item=>item.health_category===formik.values.health_complaint_keywords?.[index]?.health_category)?.list?.map((item)=>({
                                                          label: item.health_condition,
                                                          value: item.health_condition
                                                        }))
                                                         :
                                                        []
                                                      }
                                                      placeholder='Select Condition'
                                                      onChange={(e)=>{
                                                        formik.setFieldValue(`health_complaint_keywords.${index}.health_condition`, e.value)
                                                    }}
                                                      value={formik.values.health_complaint_keywords?.[index]?.health_condition ?{
                                                        label: formik.values.health_complaint_keywords?.[index]?.health_condition || 'NA',
                                                        value: formik.values.health_complaint_keywords?.[index]?.health_condition
                                                      } : null}
                                                    />
                                                </div>
                                              </div>
                                              <div className="data-row col-3">
                                                  <div className='date-input flex-grow-1'>
                                                      <div className="input">
                                                          <FastField type="text" id="" placeholder="Enter Details" name={`health_complaint_keywords.${index}.details`}/>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="data-row col-2">
                                                <div className='date-input'>
                                                    <Select
                                                      className="name-select"
                                                      classNamePrefix="select"
                                                      // isLoading={isLoading}
                                                      isSearchable={true}
                                                      name="color"
                                                      options={intensityOptions}
                                                      placeholder='Select Intensity'
                                                      onChange={(e)=>{
                                                        formik.setFieldValue(`health_complaint_keywords.${index}.intensity`, e.value)
                                                    }}
                                                      value={formik.values.health_complaint_keywords?.[index]?.intensity !== '' ?{
                                                        label: formik.values.health_complaint_keywords?.[index]?.intensity || 'NA',
                                                        value: formik.values.health_complaint_keywords?.[index]?.intensity
                                                      } : null}
                                                    />
                                                </div>
                                              </div>
                                              <div className="data-row col-3">
                                                <div className='date-input  d-flex align-items-center'>
                                                    <div className="input flex-grow-1">
                                                        <FastField type="text" id="" placeholder="Months/Years from onset till date" name={`health_complaint_keywords.${index}.duration`}/>
                                                    </div>
                                                    {formik.values.health_complaint_keywords?.length >1 && <span className='float-end ms-2' onClick={()=>arrayHelpers.remove(index)}><DeleteIcon/></span>}
                                                </div>
                                              </div>
                                            </div>
                                          )))
                                        }
                                        <div className="add-more d-flex justify-content-center">
                                          <span onClick={()=>{arrayHelpers.push({
                                            health_category: '',
                                            health_condition: '',
                                            details: '',
                                            intensity: '',
                                            duration: '',
                                          })}}>
                                            <Add/>
                                          </span>
                                        </div>
                                        {formik.values.health_complaint_keywords?.length >0 && <hr/>}
                                      </div>)}
                                  ></FieldArray>
                                  }
                                </div>
                              </div>

                              
                              <div className="data-box">
                                <div className="title">
                                  Past Medical History / Surgical History
                                  <YesNoComponentFM name="is_past_medical_history"
                                    handleClick={()=>{formik.setFieldValue("past_medical_history",[])}}
                                    yesTestID= "yes_is_past_medical_history"
                                    noTestID= "no_is_past_medical_history"
                                    handleClickYes={()=>{
                                      formik.setFieldValue("past_medical_history", [{
                                        condition: '',
                                        period: '',
                                      }])
                                  }}
                                  />
                                </div>
                                <div>
                                  {formik.values.past_medical_history?.length >0 && <hr/>}
                                  {
                                    formik.values.is_past_medical_history === '1'
                                    &&
                                    <FieldArray
                                      className='mt-3'
                                      name="past_medical_history"
                                      render={arrayHelpers => (
                                        <div className="p-2 flex flex-wrap gap-4 items-center">
                                          {formik.values.past_medical_history && formik.values.past_medical_history.length > 0 && (
                                            formik.values.past_medical_history.map((item, index) => (
                                            <div className="value w-100 d-flex " key={index}>
                                                <div className='me-3 mt-2'>&#10687;</div>
                                              <div className='row flex-grow-1'>
                                                <div className="data-row d-flex col-8 align-items-center">
                                                    <div className="title">Condition</div>
                                                    <div className='date-input flex-grow-1'>
                                                        <div className="input">
                                                            <FastField type="text" id="" placeholder="Enter Condition" name={`past_medical_history.${index}.condition`}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="data-row d-flex col-3 align-items-center">
                                                  <div className="title pe-4">Period</div>
                                                  <div className='date-input flex-grow-1'>
                                                    <Select
                                                      className="name-select"
                                                      classNamePrefix="select"
                                                      isSearchable={true}
                                                      width={'300px'}
                                                      name="color"
                                                      options={periodOptions}
                                                      placeholder='From/ To'
                                                      onChange={(e)=>{
                                                        formik.setFieldValue(`past_medical_history.${index}.period`, e.value)
                                                    }}
                                                      value={formik.values.past_medical_history?.[index]?.period ? {
                                                        label: formik.values.past_medical_history?.[index]?.period,
                                                        value: formik.values.past_medical_history?.[index]?.period
                                                      } : null}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="add-more col-1 pt-2 pe-5">
                                                {formik.values.past_medical_history?.length >1 && <span className='float-end' onClick={()=>arrayHelpers.remove(index)}><DeleteIcon/></span>}
                                                </div>
                                              </div>
                                            </div>
                                          )))
                                        }
                                        <div className="add-more d-flex justify-content-center">
                                          <span onClick={()=>{arrayHelpers.push({
                                            condition: '',
                                            period: '',
                                          })}}>
                                            <Add/>
                                          </span>
                                        </div>
                                        {formik.values.past_medical_history?.length >0 && <hr/>}
                                      </div>)}
                                    ></FieldArray>
                                  }
                                </div>
                              </div>

                              <div className="data-box">
                                <div className="title">
                                  Current Medications
                                  <YesNoComponentFM name="is_current_medications"
                                    handleClick={()=>{formik.setFieldValue("current_medications","")}}
                                    yesTestID={"yes_is_current_medications"}
                                    noTestID={"no_is_current_medications"}
                                  />
                                </div>
                                {
                                  formik.values.is_current_medications === '1'
                                  &&
                                  <div className="value">
                                    <TextFieldFM type="text" 
                                      name="current_medications"
                                      placeholder="Enter Keywords"
                                      testID={"current_medications"}
                                    />
                                  </div>
                                }
                              </div>


                              <div className="data-box">
                                <div className="title">
                                  Family History
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="family_history"
                                    placeholder="Enter Keywords"
                                    testID={"family_history"}
                                  />
                                </div>
                              </div> 
                              <div className="data-box">
                                <div className="title">
                                  Diet
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="diet"
                                    placeholder="Enter Keywords"
                                    testID={"diet"}
                                  />
                                </div>
                              </div> 
                              
                              <div className="data-box">
                                <div className="title">
                                  Allergens/ Intolerances related to food, chemicals, dust, pollen, pets, medicines etc.
                                  <YesNoComponentFM name="is_allergies"
                                    handleClick={()=>{formik.setFieldValue("allergies_keywords","")}}
                                    yesTestID={"yes_is_allergies"}
                                    noTestID={"no_is_allergies"}
                                  />
                                </div>

                                {
                                  formik.values.is_allergies === '1'
                                  &&
                                  <div className="value">
                                    <TextFieldFM type="text" 
                                      name="allergies_keywords"
                                      placeholder="Enter Keywords"
                                      testID={"allergies_keywords"}
                                    />
                                  </div>
                                }
                              </div>   
                              <div className="data-box">
                                <div className="title">Objectives of the Programme</div>
                                <div className="value">
                                  <TextFieldFM type="text" 
                                    placeholder="Enter Keywords"
                                    name="objectives"
                                    testID={"objectives"}
                                  />
                                </div>
                              </div>
                              <div className="data-box">
                                <div className="title">
                                  Recommended Stay Duration
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="recommended_stay_duration"
                                    placeholder="Enter Keywords"
                                    testID={"recommended_stay_duration"}
                                  />
                                </div>
                              </div> 
                              <div className="data-box">
                                <div className="title">
                                  Recommended Programme
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="recommended_program"
                                    placeholder="Enter Keywords"
                                    testID={"recommended_program"}
                                  />
                                </div>
                              </div> 
                              <div className="data-box">
                                <div className="title">
                                  Medical Reports Requested for
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="medical_reports_requested_for"
                                    placeholder="Enter Keywords"
                                    testID={"medical_reports_requested_for"}
                                  />
                                </div>
                              </div> 
                              <div className="data-box">
                                <div className="title">
                                  Specific requests from guest
                                </div>

                                <div className="value">
                                  <TextFieldFM type="text" 
                                    name="specific_request_from_guest"
                                    placeholder="Enter Keywords"
                                    testID={"specific_request_from_guest"}
                                  />
                                </div>
                              </div> 
                            </div>

                            <div className="mb-5 mx-2 w-50">
                              <UploadButton
                              name={'pac'}
                              files={files}
                              setFiles={setFiles}
                              />
                              </div>
                          </div>
                        </div>
                      </Form>
                    )
                  }
                }
              </Formik>
          </div>
        </div>
      </>
  )
}

export default AddPrelimAssessment