import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs} from 'react-pdf';
import  {ReactComponent as ZoomIn} from '../assets/images/zoomin.svg'
import  {ReactComponent as ZoomOut} from '../assets/images/minus.svg'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const PDFViewer = ({ url }) => {
  const [scale, setScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const ref = useRef(null);

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onLoadError = (error) => {
    console.log(error.message);
  };

  const handleScroll = (event) => {
    const pageHeight = event.target.scrollHeight / numPages * 50 /100;
    const currentPage = Math.ceil(event.target.scrollTop / pageHeight) + 1;

    if (currentPage !== pageNumber) {
      setPageNumber(currentPage);
    }
  };


  useEffect(() => {
    const divRef = ref.current;

    const handleContextMenu = (e) => {
      e.preventDefault();
    };


    if (divRef && numPages) {
      divRef.addEventListener('contextmenu', handleContextMenu);

      return () => {
        divRef.removeEventListener('contextmenu', handleContextMenu);
      };
    }

    
  }, [numPages]);
  return (
    <div className='bg-dark h-100 w-100' >
      <div className='toolbar position-sticky top-0 bg-dark text-light d-flex justify-content-center align-items-center left-0 py-2'style={{zIndex: 1}}>
        {/* {numPages ? `${pageNumber} / ${numPages}`: ''} */}
        <ZoomIn fontSize={'19px'} className='cursor-pointer' onClick={()=>setScale((prev) => Math.min(prev + 0.1, 3))} /> <span className='mx-3 px-2' style={{backgroundColor: '#484848'}}>{Math.floor(scale * 100)}% </span><ZoomOut fontSize={'19px'} className='cursor-pointer' onClick={()=>setScale((prev) => Math.max(prev - 0.1, 0.25))} />
      </div>
      <div onScroll={handleScroll} style={{height: 'calc(100% - 40px)'}} className='d-flex justify-content-center w-100  overflow-y-scroll'>
        <Document
          
          file={url}
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
        >
            
          {[...new Array(numPages)].map((_, idx) => (
            <div key={idx} style={{ marginBottom: '20px' }}>
              <Page 
                scale={scale} 
                pageNumber={idx + 1}
                loading={false} 
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              />
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
};
