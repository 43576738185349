import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './Help.scss'
import { useDispatch, useSelector } from 'react-redux';
import backArrow from '../../assets/images/back-arrow.svg'
import helpIcon from '../../assets/images/help.svg'
import fileIcon from '../../assets/images/file.svg'
import eyeIcon from '../../assets/images/eye-open.svg'
import "react-datepicker/dist/react-datepicker.css";
import { getUserManuals } from '../../redux/actions/HelpActions/HelpAction';
import { CircularProgress } from '@mui/material';
import { VIEW_HELP_ENDPOINT } from '../../routes/route';

function Help() {
   const navigate = useNavigate()
    const dispatch = useDispatch()
    const {loading, userManualList} = useSelector(state=> state.getUserManualListReducer)

    useEffect(()=>{
        dispatch(getUserManuals())
    },[])

  return (
    <>
            <div className="help-container">
                <div className="container h-100">
                    <div className="help-heading">
                    <img className='back-arrow me-3' src={backArrow} width={'23px'} alt="" onClick={()=>navigate(-1)} />
                    <img className='back-arrow me-2' src={helpIcon} width={'28px'} alt="" onClick={()=>navigate(-1)} />
                        Help
                    </div>
                    <div className="pt-4 pb-5">
                        {

                            loading ?
                            <div className="text-center">
                                <CircularProgress size={20} sx={{'color': '#74613C'}}/>
                            </div>
                            :
                            !!userManualList.length && <div className="border rounded p-3">
                                {
                                    userManualList?.map((item, index)=>{
                                        return (
                                            <div key={index} className={`${index+1===userManualList.length ? "" : "border-bottom"} d-flex align-items-center justify-content-between py-2`}>
                                                <div className="d-flex align-items-center data-row">
                                                    <img className='back-arrow me-2' src={fileIcon} width={'20px'} alt="" onClick={()=>navigate(-1)} />
                                                    {item.label}
                                                </div>
                                                {/* <Link target='_blank' className='d-flex align-items-center view-btn' to={`${item.url}#toolbar=0`}>
                                                    <img className='back-arrow me-2' src={eyeIcon} width={'17px'} alt="" onClick={()=>navigate(-1)} />
                                                    View
                                                </Link> */}

                                                <Link target='_blank' className='d-flex align-items-center view-btn' to={`${VIEW_HELP_ENDPOINT}/${item.id}`}>
                                                    <img className='back-arrow me-2' src={eyeIcon} width={'17px'} alt="" onClick={()=>navigate(-1)} />
                                                    View
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>                                  
                </div>
            </div>
        </>
  )
}

export default Help