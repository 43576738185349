
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas/index";
import rootReducer from './reducers/index'
import { composeWithDevTools } from '@redux-devtools/extension';
import { env } from "../const/constants";

const sagaMiddleware = createSagaMiddleware();

const store = createStore (
        rootReducer,
        process.env.REACT_APP_ENV === env.PROD ? applyMiddleware(sagaMiddleware): composeWithDevTools(applyMiddleware(sagaMiddleware))
    )

    
// running the sagas
sagaMiddleware.run(rootSaga);

export default store;

