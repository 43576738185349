import * as type from '../../constants';

export function programReducer(state = {
    programList: null,
    loading: false,
    error: null
  }, action) {
    switch (action.type) {
      case type.GET_ANANDA_PROGRAMS_LIST:
        return {
          ...state,
          loading: true,
          programList: [],
          error: null
        }
      case type.GET_ANANDA_PROGRAMS_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          programList: action.payload,
          error: null
        }
  
      case type.GET_ANANDA_PROGRAMS_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            programList: [],
            error: action.payload
          }
  
      default:
        return state
    }
  }
  