import React, { useEffect, useRef } from 'react'
import { getUserManuals } from '../../redux/actions/HelpActions/HelpAction'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { useParams } from 'react-router-dom'
import { PDFViewer } from '../../components/PDFViewer'

function ViewUserManual() {
    const params = useParams()
    let id=params?.id ? parseInt(params?.id) : ''
    const dispatch = useDispatch()
    const {loading, userManualList} = useSelector(state=> state.getUserManualListReducer)

    useEffect(() => {
      dispatch(getUserManuals())
    }, []);

  return (
    <div style={{height: 'calc(100vh - 65px)'}}>
        {
            loading ?
            <div className="text-center bg-dark pt-5 h-100 ">
                <CircularProgress size={20} sx={{'color': '#5ebd78'}}/>
            </div>
            :
            // userManualList?.find(item=> item.id===id)?.url && <iframe  ref={iframeRef} width={'100%'} height={'100%'} src={`${userManualList?.find(item=> item.id===id)?.url}#toolbar=0`} frameborder="0"></iframe>
            <PDFViewer url={userManualList?.find(item=> item.id===id)?.url} />
        }
    </div>
  )
}

export default ViewUserManual