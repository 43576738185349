import { all } from 'redux-saga/effects';
import loginSaga from './AuthSaga/Login/LoginSaga';
import loginVerifyOTPSaga from './AuthSaga/Login/LoginVerifyOTPSaga';
import loginResendOTPSaga from './AuthSaga/Login/LoginResendOTPSaga';
import updateSaga from './AuthSaga/Update/UpdateSaga';
import updateVerifyOTPSaga from './AuthSaga/Update/UpdateVerifyOTPSaga';
import updateResendOTPSaga from './AuthSaga/Update/UpdateResendOTPSaga';
import logoutSaga from './AuthSaga/Logout/LogoutSaga'
import guestListSaga from './GuestListSaga/GuestListSaga';
import viewProfileSaga from './AuthSaga/Profile/ViewProfileSaga';
import updateProfileSaga from './AuthSaga/Profile/UpdateProfileSaga';
import prelimAssessmentSaga from './GuestDataSaga/PrelimAssessmentSaga';
import wellbeingReportSaga from './GuestDataSaga/WellbeingReportSaga';
import guestDetailsSaga from './GuestDataSaga/GuestDetails';
import preparatoryDietSaga from './GuestDataSaga/PreparatoryDietSaga';
import consultationNotesSaga from './GuestDataSaga/ConsultationNotesSaga';
import sessionsSaga from './GuestDataSaga/SessionsSaga';
import querySaga from './QuerySaga/QuerySaga';
import groupClassesAndActiviytySaga from './GroupClassAndActivitySagas/GroupClassAndActivitySaga';
import StatusReportSaga from './StatusReportSaga/StatusReportSaga';
import TestimonialSaga from './GuestDataSaga/TestimonialSaga';
import ProgrammeFeedbackSaga from './GuestDataSaga/ProgrammeFeedbackSaga';
import ProgramSaga from './ProgramSagas/ProgramSaga';
import forgotPasswordSaga from './AuthSaga/ForgotPassword/ForgotPasswordSaga';
import PasswordSaga from './AuthSaga/PasswordSaga/PasswordSaga';
import HelpSaga from './HelpSaga/HelpSaga';

export default function* rootSaga() {
    return yield all([
        loginSaga(),
        loginVerifyOTPSaga(),
        loginResendOTPSaga(),
        updateSaga(),
        updateVerifyOTPSaga(),
        updateResendOTPSaga(),
        logoutSaga(),
        guestListSaga(),
        viewProfileSaga(),
        updateProfileSaga(),
        prelimAssessmentSaga(),
        wellbeingReportSaga(),
        preparatoryDietSaga(),
        consultationNotesSaga(),
        sessionsSaga(),
        guestDetailsSaga(),
        querySaga(),
        groupClassesAndActiviytySaga(),
        StatusReportSaga(),
        TestimonialSaga(),
        ProgrammeFeedbackSaga(),
        ProgramSaga(),
        forgotPasswordSaga(),
        PasswordSaga(),
        HelpSaga()
    ]);
}