import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditVitalsCheck.scss'
import { ADD, DEPARTURE, VitalsCheckField } from '../../NoteFormsConstants'
import { useDispatch, useSelector } from 'react-redux'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { parseJSON } from '../../../../../utils/helpers/universalFunctions'
import {ReactComponent as FileIcon} from '../../../../../assets/images/file.svg'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'


export const AddEditVitalsCheck = ({content, setContent, conductedBy, type,  guestId, userProgramId, formType, uploadComponent})=>{
  const dispatch = useDispatch()
  const[arrivalDataFiles, setArrivalDataFiles] = useState({})
  const {consultationNotesData, loading} = useSelector(state=> state.consultationNotesReducer)
  const setVitalCheckForm = (key, value)=>{
      setContent(prev=>{
        let newData = { ...prev}
        newData.data[key] = {...newData.data[key], value};
        return newData;
      })
    }

    useEffect(()=>{
      if(guestId && userProgramId && type===DEPARTURE && formType===ADD){
          dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Vitals Check - Arrival"}))
      }
    },[])

    useEffect(()=>{
      if(type===DEPARTURE){
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if( formType===ADD){ //have dummy JSON
          setContent(prev=>{
            let newData= {...prev};
            newData.date = moment().format("YYYY-MM-DD")
            if(notes_detail){
              for (let key in newData.data) {
                newData.data[key].arrival_reading = notes_detail?.data[key]?.value
              }
            }
            return newData;
          })

          if(notes_detail)
            setArrivalDataFiles(p=>{
    
              return {
                notes_doc : consultationNotesData?.[0]?.list?.[0]?.notes_doc,
                notes_url : consultationNotesData?.[0]?.list?.[0]?.notes_url
              }
            })
        }
      }else{
        setContent(prev=>{
          let newData= {...prev};
          newData.date = moment().format("YYYY-MM-DD")
          return newData;
        })
      }
    },[consultationNotesData])

    return (
      <div className=''>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <div className='text-danger border border-danger rounded py-2 fw-semi-bold text-center my-2'>
                THIS ENTIRE FORM WILL BE AUTOMATICALLY VISIBLE TO GUEST ON SUBMISSION
            </div>
            <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="d-flex p-3 border rounded  justify-content-between w-100">
              {
                type===DEPARTURE &&
                <div className='vitals-check-old-form'>
                  <div className="fw-semi-bold mb-4 text-uppercase">Arrival Data</div>

                  <div>
                    <div className='position-relative'>
                      {
                        VitalsCheckField?.map((item, index)=>(
                          <div className="data-row mb-4" key={index}>
                            <div className="title text-secondary">{item.label}</div>
                            <div className='data-input'>
                            {content?.data?.[item.key]?.arrival_reading || '- '} {content?.data?.[item.key]?.unit || ''} 

                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              <div className="vitals-check-form  ">
                {
                  type===DEPARTURE && <div className="fw-semi-bold mb-4 text-uppercase">Departure Data</div>
                }

              {
                  VitalsCheckField?.map((item, index)=>(
                    <div className="data-row " key={index}>
                      <div className="title">{item.label}</div>
                      <div className='data-input'>
                          {
                          item.type!=='textarea'?
                          <div className='input-container'>
                            <TextField 
                              type={item.type}
                              placeholder='Enter value' 
                              value={content?.data?.[item.key]?.value || ''} 
                              onChange={e=>setVitalCheckForm(item.key, e.target.value)}
                              unit={item.unit}
                            />
                          </div>
                          :
                          <TextArea
                            placeholder={"Enter value"}
                            onChange={value =>setVitalCheckForm(item.key, value)}
                            value={content?.data?.[item.key] || ''}/>
                          }
                        <div className='mt-1 machine-name'>
                          {item.machine}
                        </div>
                      </div>
                    </div>
                  ))
              }
              {uploadComponent}
              </div>
            
            </div>
          </>
        }
        
      </div>
    )
}