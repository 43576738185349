import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './SessionsTab.scss'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Rating, Tooltip } from '@mui/material'
import GuestDetailsHeader from '../../../components/GuestDetailsHeader/GuestDetailsHeader'
import 'react-quill/dist/quill.snow.css';
import arrowDownIcon from '../../../assets/images/arrow-down-brown.svg'
import {getDayWiseUserSchedule} from '../../../redux/actions/GuestDataActions/SessionsActions'
import * as routes from '../../../routes/route'
import ImageComponent from '../../../components/ImageComponent/ImageComponent'
import VideoComponent from '../../../components/VideoComponent/VideoComponent'
import ReactQuill from 'react-quill'
import CommentModal from '../../../components/CommentModal/CommentModal'

const messages=["Sessions", "Notes", "Reviews"]

function SessionsTab({currentTab}) {
    
    const location = useLocation();
    const { guestId, userProgramId, type} = location.state;
    const sessionReducer = useSelector(state=> state.sessionReducer)
    const [sessionData, setSessionData] = useState()

    const {loading, guestDetails} = useSelector(state=> state.guestDetailsReducer)
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const canWrite = (permissions?.session_module?.permissions || '').includes('write')


    useEffect(()=>{
      setSessionData(prev=>{
        let data = sessionReducer?.sessionData
        if(currentTab===2){
            data = data?.map(dayObject => {
            const filteredPlans = dayObject.plan.filter(plan => plan.is_session_review_filled === 1);
              if (filteredPlans.length > 0) {
                return {
                    day: dayObject.day,
                    plan: filteredPlans
                };
              }
              return null;
            }).filter(dayObject => dayObject !== null);
            return data
        }else if(currentTab===1){
            data = data?.map(dayObject => {
              const filteredPlans = dayObject.plan.filter(plan => plan.s_session_notes_status === 1);
                if (filteredPlans.length > 0) {
                  return {
                      day: dayObject.day,
                      plan: filteredPlans
                  };
                }
                return null;
              }).filter(dayObject => dayObject !== null);
              return data
        }else{
          return data
        }
      })
    }, [sessionReducer, currentTab])

  return (
        <>
            <div className="sessions-container">
                <div className="h-100">
                    {/* <div className="sessions-heading">
                      {currentTab===2 || currentTab===1 ? `Session ${capitalize(type)}` : 'Sessions'}
                    </div> */}

                    {   loading && (guestId !== guestDetails?.user_id || !guestDetails) ?
                        <div className="loader">
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>

                        :

                        <div className="pb-5">
                            <div className="sessions-content">
                                <GuestDetailsHeader guestId={guestId} />

                                {
                                    sessionReducer?.loading ? 
                                    <div className="loader">
                                        <CircularProgress sx={{'color': '#74613C'}} size={20}/>
                                    </div>
                                    :
                                    <>
                                        {
                                          sessionData?.map((session, index)=>{
                                            return (
                                              <SessionDay
                                                canWrite={canWrite}
                                                session={session}
                                                key={index}
                                                guestId={guestId}
                                                expandAccordion={currentTab===2 || currentTab===1}
                                              />
                                            )
                                          })
                                        }
                                        
                                    </>
                                }
                                {
                                  !loading && !sessionData?.length
                                  &&
                                  <div className='loader'>
                                    No {messages[currentTab]}
                                  </div>
                                }
                            </div>
                        </div>
                    }                
                </div>
            </div>
        </>
  )
}

export default SessionsTab

const getDuration = (duration) => {
  let h = Math.floor(duration / 60)
  let m = duration % 60
  let res = (h > 0 ? (h > 9 ? h : `0${h}`) + (h > 1 ? ' hours ' : ` hour `) : '') +
      (m > 0 ? (m > 9 ? m : `0${m}`) + (m > 1 ? ' minutes' : ` minute`) : '')
  return res;
}

function SessionDay({session, guestId, canWrite, expandAccordion}) {
  const [toggle, setToggle] = useState(expandAccordion ? true : false);
  const [viewComment, setViewComment] = useState(false);
  const STATIC_SESSTIONS = ["Check-In", "Check-Out"]
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('UserData'))

  const handleAdd = (item)=>{
    const data={
      guestId,
      sessionId: item?.s_user_session_id,
      startTime: item?.s_session_start_time,
      title: item?.s_title
    }

    if(!STATIC_SESSTIONS.includes(item.s_title) && item?.s_session_notes_status === 1 ? true : canWrite){
      if(canWrite){
        if(item?.s_session_notes_status === 1)
          navigate(routes.EDIT_SESSION_NOTES_ENDPOINT, { 
            state: {...data}
          })
        else
          navigate(routes.ADD_SESSION_NOTES_ENDPOINT, { 
            state: {...data}
          }) 
      }else{
        navigate(routes.VIEW_SESSION_NOTES_ENDPOINT, { 
          state: {...data}
        }) 
      }
    }
  }

  return (
    <div className='px-3'>
      <Accordion 
      expanded={toggle}
      onChange = {(e,expanded) => {
          if(expanded){
              setToggle(true)
          }else{
              setToggle(false)
          }
      }}>
          <AccordionSummary
            expandIcon={<img src={arrowDownIcon}/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
            >
          <div className='accordian-title ms-3'>
              {moment(session?.plan[0]?.s_session_start_time).format('dddd, DD MMM')}
          </div>
          </AccordionSummary>
          <AccordionDetails>
            {
              session?.plan?.map((item, index) => {
              let duration = moment(item.s_session_end_time).diff(moment(item.s_session_start_time), 'minute');
              return (
                <div className='single-session-container' key={item?.s_user_session_id}>
                  <div className="indicator">
                  </div>
                  <div className={session?.plan?.length !== index+1 ? "content content-border pb-4" : "content"}>
                    <div className="d-flex start-time justify-content-between w-100 mb-1">
                      <span className='text-muted'>{moment(item.s_session_start_time).format('HH:mm A')}</span>
                      
                    </div>
                    <div className="about-session ms-1 mb-2 d-flex">
                      {item?.media &&
                        <div className="media-box">
                          {
                            item?.media_type ==='image' && item?.media_type ?
                              <ImageComponent src={item?.media} fetch={true}/>
                              : 
                              <VideoComponent src={item?.media} />
                          }
                        </div>
                      }
                      <div className='d-flex w-100 justify-content-between'>
                        <div className='flex-grow-1' >
                          <div className="fw-bold">{item.s_title}</div>
                          {!STATIC_SESSTIONS.includes(item.s_title) ? null :
                              <>
                                  {duration ? <div> <span className='text-muted'>Duration:</span> {getDuration(duration)} </div> : null}
                              </>
                          }
                          <div className='quilDiv mt-2 me-2'>
                            {
                              !!item.s_session_notes_status &&
                              <div className="d-flex ps-4 fst-italic">
                                <div className=' fw-bold text-muted me-2'>
                                  Note:
                                </div>
                                  <ReactQuill
                                    className='quillMainDiv'
                                    theme="snow"
                                    value={item?.session_notes}
                                    readOnly={true}
                                  />
                              </div>
                            }
                            <div className='d-flex'>
                              {
                                !!item.s_session_notes_status &&
                                <div className="text-muted me-3 fst-italic update-by"> <span className='text-muted me-2 ps-4 '>  Updated By: </span> {item.note_added_by || '-'}</div>
                              }
                              {(!STATIC_SESSTIONS.includes(item.s_title) && item?.s_session_notes_status === 1 ? true : canWrite) &&
                                <span className='add-link' onClick={() => handleAdd(item)}>
                                    <span >
                                      {
                                        canWrite 
                                          ? item?.s_session_notes_status === 1 
                                            ? (item?.note_added_by_id?.toString() === userData?.user_id?.toString() ? "Update Note" : "")
                                            : "Add Note" 
                                          : 'View Note'
                                      }
                                    </span>
                                </span>
                              }
                            </div>
                          </div>

                        </div>
                        {
                          !!item.is_session_review_filled &&
                          <div className='d-flex flex-column flex-shrink-0 align-items-center'>
                            <Rating name="read-only" size='small' value={item?.session_ratings} readOnly />
                            {
                              !!item.review_text && <div href="#" className='view-comment' onClick={()=>setViewComment(true)}> View Comment</div>
                            }
                            
                            <CommentModal
                                text={item.review_text}
                                warnModal={viewComment} 
                                buttonText="Open"
                                setWarnModal={setViewComment} 
                                />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            }
          </AccordionDetails>
      </Accordion>
    </div>
  )
}