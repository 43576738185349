import axios from "axios";
import { decryptData, encryptData } from "../utils/helpers/encryptionDecryption";
import { REACT_APP_BASE_URL } from "../config/constant";
import { logger } from "../utils/logging";

const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {'Cache-Control': 'no-cache'},
});


//- Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    logger.log("config", config.data);
    let userData = JSON.parse(localStorage.getItem('UserData'));
    //- Do something before request is sent
    if (config.data) {
      const { data } = config.data
      if (config.data instanceof FormData === false) {
        config.data = { data: { newData: encryptData(data) } };
      }
    }

    if (userData?.access_token) {
        config.headers['Authorization'] = `Bearer ${userData.access_token}`;
    }
    return config;
  },
  function (error) {
      //- Do something with request error
      return Promise.reject(error);
  }
);

//- Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
      logger.log("interceptorresponse", response);

      //- return response;
      if (response.data instanceof ArrayBuffer)
        return response
      return decryptData(response.data);
  },
  function (error) {
      return Promise.reject(error);
  }
);

export default axiosInstance;