import * as type from '../../constants';

const initialState = {
  loading: false,
  updateLoder: null,
  statusReport: null,
  loadingFilterList: false,
  filterList: null,
  error: null
}

export function statusReportReducer(state = initialState, action) {
  switch (action.type) {

    case type.GET_WELLNESS_STATUS_REPORT:
        return {
            ...state,
            loading: true,
        }
    case type.GET_WELLNESS_STATUS_REPORT_SUCCESS:
        return {
            ...state,
            loading: false,
            statusReport:action.payload,
            error: null
        }
    case type.GET_WELLNESS_STATUS_REPORT_FALIURE:
        return {
            ...state,
            loading: false,
            statusReport: null
        }

    case type.GET_STATUS_REPORT_FILTER_LIST:
        return {
            ...state,
            loadingFilterList: true,
        }

    case type.GET_STATUS_REPORT_FILTER_LIST_SUCCESS:
        return {
            ...state,
            loadingFilterList: false,
            filterList: action.payload,
        }

    case type.GET_STATUS_REPORT_FILTER_LIST_FALIURE:
        return {
            ...state,
            loadingFilterList: false,
        }

    default:
      return state
  }
}

