import { useEffect, useState } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditAyurvedaDepartureConsultation.scss'
import { ADD, AyurvedaArrivalConsultationJSON, AyurvedaDepartureConsultationField, AyurvedaDepartureConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { CircularProgress } from '@mui/material'
import targetIcon from '../../../../../assets/images/target.svg'
import Select from "react-select";

const Medicines = ({setKey, removeInOtherRecommendation, content})=>{
    return (
        <div>
            {
                content.data?.other_recommendations?.medicine?.map((item, index)=>{
                    return (
                        <div className="row mb-2" key={index}>
                            <div className='col-4'>
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["other_recommendations", "medicine", index, "medicines"], value)}
                                    value={content?.data?.other_recommendations?.medicine?.[index]?.medicines || ''}/>
                            </div>
                            <div className='col-4'>
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["other_recommendations", "medicine", index, "dosage"], value)}
                                    value={content?.data?.other_recommendations?.medicine?.[index]?.dosage || ''}/>
                            </div>
                            <div className='col-4 align-items-center d-flex'>
                                <div className="flex-grow-1 me-3">
                                    <TextArea
                                        row={2}
                                        placeholder={"Enter "}
                                        onChange={value =>setKey(["other_recommendations", "medicine", index, "frequency"], value)}
                                        value={content?.data?.other_recommendations?.medicine?.[index]?.frequency || ''}/>
                                </div>
                                <div className='remove-btn' onClick={()=>removeInOtherRecommendation(index)}>
                                    <RemoveBtn/>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="d-flex flex-wrap align-items-stretch justify-content-start" key={content?.data?.programme_and_treatment_plan?.objectives?.length}>
            {
                content?.data?.programme_and_treatment_plan?.objectives?.map((subitem, subindex)=> {
                    return (
                        <div className="objective-box p-2" key={subindex}>
                            <div className='border rounded'>
                                <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                    <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>
                                    <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                        <RemoveBtn/>
                                    </div>
                                </div>
                                <div className="p-3">
                                {
                                    AyurvedaDepartureConsultationField?.objectives?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="fw-semi-bold mt-2" style={{width: '260px'}}>{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["programme_and_treatment_plan", "objectives", subindex, item.key], value)}
                                                        row={2}
                                                        value={content?.data?.programme_and_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className="p-2 w-50 "  onClick={handleAddMore}>
                <div className="add-more ">
                    Add More Objectives
                </div>
            </div>
        </div>
    )
}


export const AddEditAyurvedaDepartureConsultation = ({content, setContent, conductedBy, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
    const {consultationNotesData, programmeName, followUpTimeline, loading} = useSelector(state=> state.consultationNotesReducer)
    const programList = useSelector(state=> state.programReducer.programList)


  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeInOtherRecommendation = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.other_recommendations.medicine.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMoreInOtherRecommendation=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.other_recommendations.medicine = [...updatedData.data.other_recommendations.medicine, AyurvedaDepartureConsultationJSON.data.other_recommendations.medicine[0]]
            return updatedData;
        })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.programme_and_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.programme_and_treatment_plan.objectives = [...updatedData.data.programme_and_treatment_plan.objectives, AyurvedaArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }


    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Ayurveda Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(formType===ADD){ //have dummy JSON
            setContent(prev=>{
                let newData= {...prev};
                newData.date = moment().format("YYYY-MM-DD")

                if(notes_detail){
                    newData.data.programme_and_treatment_plan = {
                        "dosha_prakriti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_prakriti,
                        "dosha_vikruiti": notes_detail?.data?.summary_assessment_treatment_plan?.dosha_vikruiti,
                        "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
                    }
    
                    for (let key in notes_detail.data.ayurvedic_health_assessment) {
                        newData.data.health_assessment[key].arrival_reading = notes_detail?.data.ayurvedic_health_assessment[key]
                        newData.data.health_assessment[key].value = notes_detail?.data.ayurvedic_health_assessment[key]
                    }
                    for (let key in notes_detail.data.ashthavidha_pariksha) {
                        newData.data.ashthavidha_pariksha[key].arrival_reading = notes_detail?.data.ashthavidha_pariksha[key]
                        newData.data.ashthavidha_pariksha[key].value = notes_detail?.data.ashthavidha_pariksha[key]
                    }
    
                    let systemic_assessment = notes_detail?.data.systemic_assessment
                    newData.data.systemic_assessment.respiratory_system.lung_field.arrival_reading = systemic_assessment.respiratory_system.lung_field
                    newData.data.systemic_assessment.respiratory_system.auscultation.arrival_reading = systemic_assessment.respiratory_system.auscultation.value
                    newData.data.systemic_assessment.cardiovascular_system.pulse_rate.arrival_reading = systemic_assessment.cardiovascular_system.pulse_rate.value
                    newData.data.systemic_assessment.cardiovascular_system.blood_pressure.arrival_reading = systemic_assessment.cardiovascular_system.blood_pressure.value
                    newData.data.systemic_assessment.cardiovascular_system.spo2.arrival_reading = systemic_assessment.cardiovascular_system.spo2.value
                    newData.data.systemic_assessment.cardiovascular_system.remarks.arrival_reading = systemic_assessment.cardiovascular_system.remarks
                    newData.data.systemic_assessment.musculoskeletal_system.arrival_reading = systemic_assessment.musculoskeletal_system
                    newData.data.systemic_assessment.skin.arrival_reading = systemic_assessment.skin
                    newData.data.lab_reports.arrival_reading = notes_detail?.data.lab_reports
    
                    newData.data.systemic_assessment.respiratory_system.lung_field.value = systemic_assessment.respiratory_system.lung_field
                    newData.data.systemic_assessment.respiratory_system.auscultation.value = systemic_assessment.respiratory_system.auscultation.value
                    newData.data.systemic_assessment.cardiovascular_system.pulse_rate.value = systemic_assessment.cardiovascular_system.pulse_rate.value
                    newData.data.systemic_assessment.cardiovascular_system.blood_pressure.value = systemic_assessment.cardiovascular_system.blood_pressure.value
                    newData.data.systemic_assessment.cardiovascular_system.spo2.value = systemic_assessment.cardiovascular_system.spo2.value
                    newData.data.systemic_assessment.cardiovascular_system.remarks.value = systemic_assessment.cardiovascular_system.remarks
                    newData.data.systemic_assessment.musculoskeletal_system.value = systemic_assessment.musculoskeletal_system
                    newData.data.systemic_assessment.skin.value = systemic_assessment.skin
                    newData.data.lab_reports.value = notes_detail?.data.lab_reports
                    newData.data.current_health_complaints = notes_detail?.data?.current_health_complaints
                }
                return newData;


            })
        }
    },[consultationNotesData])

    return (
      <div className='ayurveda-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>

                <div className='text-danger border border-danger rounded py-2 fw-semi-bold text-center my-2'>
                    THIS ENTIRE FORM WILL BE AUTOMATICALLY VISIBLE TO GUEST ON SUBMISSION
                </div>
                <div className="d-flex py-4 mb-2 justify-content-between">
                    <div className="d-flex align-items-center">
                        <div className="title text-secondary me-3">Conducted By: </div>
                        {/* <TextField
                            width={'300px'}
                            type={'text'}
                            placeholder='Enter name' 
                            value={content?.conducted_by || ''} 
                            onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                        /> */}
                        {conductedBy || '-'}
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="title text-secondary me-3">Date</div>
                        <DatePicker
                        value={content.date ? new Date(content.date) : ''}
                        onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                        width={'300px'}
                        />
                    </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Ayurvedic Physician during post-stay online follow-ups.  
                </div>
                <div className="border w-100 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    PROGRAMME & TREATMENT PLAN
                    </div>
                    <div className='p-3'>
                        <div className='d-flex mb-2'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Prakriti:</div>
                            <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                        </div>
                        <div className='d-flex mb-2'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Vikruti (to be balanced):</div>
                            <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                        </div>
                        <div className='fw-semibold mt-5 mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>

                        <Objectives
                            content={content}
                            removeObjective={removeObjective}
                            setKey={setKey}
                            handleAddMore={handleAddMore}
                        />
                    </div>
                </div>
                <div className='border rounded overflow-hidden my-3'>
                <div className="heading py-3">
                    CURRENT HEALTH COMPLAINTS
                </div>
                    <div className=''>
                        {
                            content.data?.current_health_complaints?.is_current_health_complaint === 1 
                            ?
                            <div className=''>
                                <div className="row m-0 fw-semibold ">
                                    <div className="border py-1 col-2">Health Category</div>
                                    <div className="border py-1 col-2">Health Condition</div>
                                    <div className="border py-1 col-3">Details</div>
                                    <div className="border py-1 col-2">Intensity</div>
                                    <div className="border py-1 col-3">Duration</div>
                                </div>
                                <div>
                                {
                                    content?.data.current_health_complaints.health_complaint_keywords?.map((item, index)=>{
                                        return (
                                            <div className="row m-0" key={index}>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['health_category'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['health_condition'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-3">
                                                    {item?.['details'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['intensity'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-3">
                                                    {item?.['duration'] || '-'}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                            :
                            <div className='m-3'>No</div>
                        }
                    </div>                
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    AYURVEDIC HEALTH ASSESSMENT
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Arrival
                            </div>
                            <div className='col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                AyurvedaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 mb-3" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4'>
                                                {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='col-5'>
                                                <div className="data-input h-100">
                                                    <TextArea
                                                        row={3}
                                                        placeholder={"Remarks "}
                                                        fullHeight={true}
                                                        onChange={value =>setKey(["health_assessment", item.key, "value"], value)}
                                                        value={content?.data?.health_assessment?.[item.key]?.value || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic">
                        <div className="heading py-3">
                            SYSTEMIC  ASSESSMENT
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading my-2">
                                Respiratory System:
                            </div>
                            <div className="row m-0 border-top text-muted border-bottom py-2 fw-semi-bold">
                                <div className="col-2"></div>
                                <div className="col-5">On Arrival</div>
                                <div className="col-5">On Departure</div>
                            </div>
                            <div className='py-3'>
                                <div className="row m-0">
                                    <div className="title col-2">Lung Field: </div>
                                    <div className="col-5">
                                        <div className='data-input '>
                                            {content?.data?.systemic_assessment?.respiratory_system?.["lung_field"]?.arrival_reading || '-'}
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className='data-input '>
                                            <TextArea
                                                row={3}
                                                placeholder={"Remarks "}
                                                onChange={value =>setKey(["systemic_assessment", "respiratory_system", "lung_field", "value"], value)}
                                                value={content?.data?.systemic_assessment?.respiratory_system?.["lung_field"]?.value || ''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="title col-2">Auscultation: </div>
                                    <div className="col-5">
                                        <div className='data-input '>
                                            {content?.data?.systemic_assessment?.respiratory_system?.["auscultation"]?.arrival_reading || '-'}
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className='data-input '>
                                            <TextArea
                                                row={3}
                                                placeholder={"Remarks "}
                                                onChange={value =>setKey(["systemic_assessment", "respiratory_system", "auscultation", "value"], value)}
                                                value={content?.data?.systemic_assessment?.respiratory_system?.["auscultation"]?.value || ''}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='p-3'>
                            <div className="sub-heading">
                            Cardiovascular System:
                            </div>
                            <div className='py-3'>
                                <div className="row m-0 border-top text-muted border-bottom py-2 fw-semi-bold">
                                    <div className="col-2"></div>
                                    <div className="col-5">On Arrival</div>
                                    <div className="col-5">On Departure</div>
                                </div>
                                <div className='py-3'>
                                    <div className="row m-0 mb-2">
                                        <div className="title col-2">Pluse Rate: </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                                {content?.data?.systemic_assessment?.cardiovascular_system?.["pulse_rate"]?.arrival_reading || '-'}
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                            <TextField 
                                                type={'item.type'}
                                                placeholder='Enter value' 
                                                value={content?.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.value || ''} 
                                                onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "pulse_rate", "value"], e.target.value)}
                                                unit={content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.unit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-2">
                                        <div className="title col-2">Blood Pressure: </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                                {content?.data?.systemic_assessment?.cardiovascular_system?.["blood_pressure"]?.arrival_reading || '-'}
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                            <TextField 
                                                type={'item.type'}
                                                placeholder='Enter value' 
                                                value={content?.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.value || ''} 
                                                onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "blood_pressure", "value"], e.target.value)}
                                                unit={content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.unit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 mb-2">
                                        <div className="title col-2">SpO<sub>2</sub>: </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                                {content?.data?.systemic_assessment?.cardiovascular_system?.["spo2"]?.arrival_reading || '-'}
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                            <TextField 
                                                type={'item.type'}
                                                placeholder='Enter value' 
                                                value={content?.data?.systemic_assessment?.cardiovascular_system?.spo2?.value || ''} 
                                                onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "spo2", "value"], e.target.value)}
                                                unit={content.data?.systemic_assessment?.cardiovascular_system?.spo2?.unit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="title col-2">Remarks: </div>
                                        <div className="col-5">
                                            <div className='data-input '>
                                                {content?.data?.systemic_assessment?.cardiovascular_system?.["remarks"]?.arrival_reading || '-'}
                                            </div>
                                        </div>
                                        <div className="col-5">
                                        <TextArea
                                            row={3}
                                            placeholder={"Remarks "}
                                            onChange={value =>setKey(["systemic_assessment", "cardiovascular_system", "remarks", "value"], value)}
                                            value={content?.data?.systemic_assessment?.cardiovascular_system?.remarks?.value || ''}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-3'>
                            <div className="sub-heading">
                                Musculoskeletal system:
                            </div>
                            <div className='text-muted'>
                                Region, Pain, tenderness, ROM
                            </div>
                            <div className="row m-0 mt-3 border-top text-muted border-bottom py-2 fw-semi-bold">
                                <div className="col-6">On Arrival</div>
                                <div className="col-6">On Departure</div>
                            </div>
                            <div className='py-3'>
                                <div className="row m-0">
                                    <div className="col-6">
                                        <div className='data-input '>
                                            {content?.data?.systemic_assessment?.["musculoskeletal_system"]?.arrival_reading || '-'}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className='data-input '>
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["systemic_assessment", "musculoskeletal_system", "value"], value)}
                                            value={content?.data?.systemic_assessment?.musculoskeletal_system.value || ''}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='px-3'>
                            <div className="sub-heading">
                                Skin:
                            </div>
                            <div className="row m-0 mt-3 border-top text-muted border-bottom py-2 fw-semi-bold">
                                <div className="col-6">On Arrival</div>
                                <div className="col-6">On Departure</div>
                            </div>
                            <div className='py-3'>
                                <div className="row m-0">
                                    <div className="col-6">
                                        <div className='data-input '>
                                            {content?.data?.systemic_assessment?.["skin"]?.arrival_reading || '-'}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className='data-input '>
                                        <TextArea
                                            row={3}
                                            placeholder={"Enter "}
                                            onChange={value =>setKey(["systemic_assessment", "skin", "value"], value)}
                                            value={content?.data?.systemic_assessment?.skin.value || ''}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                </div>
                    <div className="border w-100 rounded mt-5 ashtavidha">
                        <div className="heading py-3">
                        ASHTAVIDHA PARIKSHA
                        </div>
                        <div className='p-3'>
                            <div className="row m-0 mb-2 border-top text-muted border-bottom py-2 fw-semi-bold">
                                <div className="col-3"></div>
                                <div className="col-4">On Arrival</div>
                                <div className="col-5">On Departure</div>
                            </div>
                            {                   
                                AyurvedaDepartureConsultationField?.ashthavidhaPariksha?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className="col-3">
                                                <div className='title'>
                                                    {item.label}
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className='data-input '>
                                                {content?.data?.ashthavidha_pariksha?.[item.key]?.arrival_reading || '-'}
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className='data-input '>
                                                <TextArea
                                                    row={3}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["ashthavidha_pariksha", item.key, "value"], value)}
                                                    value={content?.data?.ashthavidha_pariksha?.[item.key]?.value || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="border w-100 rounded mt-5">
                        <div className="heading py-3">
                            LAB REPORTS/ OTHER OBSERVATIONS
                        </div>
                        <div className='p-3'>
                            <div className="row m-0">
                                <div className="text-muted fw-semi-bold col-3">
                                    On Arrival
                                </div>
                                <div className='col-9'>
                                    {content?.data?.lab_reports?.arrival_reading || '-'}
                                </div>
                            </div>
                            <div className="row mt-3 m-0">
                                <div className="text-muted fw-semi-bold col-3">
                                    On Departure
                                </div>
                                <div className='col-9'>
                                    <TextArea
                                        placeholder={"Enter "}
                                        onChange={value =>setKey(["lab_reports", "value"], value)}
                                        value={content?.data?.lab_reports?.value || ''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom mb-3" >
                            <div className="mt-2 col-3">Daily Routine</div>
                            <div className='col-4'>
                                Time
                            </div>
                            <div className='col-5'>
                                Frequency
                            </div>
                        </div>
                            {
                                AyurvedaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                    return (
                                        <div className="row w-100" key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4 pt-2'>
                                                <TimeSelector
                                                    width={'100%'}
                                                    ampm={true}
                                                    time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key]?.time) || ''}
                                                    setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, "time"], moment(new Date(value)).format('hh:mm A')) }
                                                    
                                                />
                                            </div>
                                            <div className='col-5'>
                                                <div className="data-input">
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Remarks "}
                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, "frequency"], value)}
                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || ''}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className="border w-100 rounded mt-5 ashtavidha">
                    <div className="heading py-3">
                        OTHER RECOMMENDATIONS
                    </div>
                    <div className='p-3'>
                        <div className="border-bottom fw-semibold mb-3 text-uppercase pb-3 text-center">Dietary Recommendations</div>
                        {
                            AyurvedaDepartureConsultationField?.otherRecommendations?.dietaryRecommendation?.map((item, index)=>{
                                return (
                                    <div className="data-row" key={index}>
                                        <div className="title mt-2">{item.label}</div>
                                        <div className='data-input'>
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["other_recommendations", "dietary", item.key], value)}
                                                value={content?.data?.other_recommendations?.dietary?.[item.key] || ''}/>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="border-bottom border-top fw-semibold mt-5 mb-3 text-uppercase py-3 text-center">Physical & Mental Activity</div>
                        {
                            AyurvedaDepartureConsultationField?.otherRecommendations?.physicalAndMentalActivity?.map((item, index)=>{
                                return (
                                    <div className="data-row" key={index}>
                                        <div className="title mt-2">{item.label}</div>
                                        <div className='data-input'>
                                            <TextArea
                                                row={3}
                                                placeholder={"Enter "}
                                                onChange={value =>setKey(["other_recommendations", "physical_mental", item.key], value)}
                                                value={content?.data?.other_recommendations?.physical_mental?.[item.key] || ''}/>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="border-bottom border-top fw-semibold mt-5 text-uppercase py-3 text-center">Medicines Recommended</div>
                        <div className="border-bottom text-muted fw-semibold mb-3 py-3 text-center row">
                            <div className="col-4">Medicine</div>
                            <div className="col-4">Dosage</div>
                            <div className="col-4">Frequency</div>
                        </div>

                        <Medicines 
                            content={content}
                            removeInOtherRecommendation={removeInOtherRecommendation}
                            setKey={setKey}
                        />
                        <div>
                            <div className='cursor-pointer mx-auto' style={{width: 'fit-content'}} onClick={()=>handleAddMoreInOtherRecommendation()}>
                                <Add/>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className=' '>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "consultation"], value)}
                                    value={content?.data?.follow_up_programme?.consultation || ''}/>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={
                                        programList ? programList?.map(item=> ({
                                          label: item.title,
                                          value: item.title
                                        }))
                                        : []
                                    }
                                    placeholder='Select'
                                    onChange={e =>setKey(["follow_up_programme", "programme"], e.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "timeline"], value)}
                                    value={content?.data?.follow_up_programme?.timeline || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
            {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}