import { 
    ADD_PRE_ASSESSMENT, 
    DEL_PRE_ASSESSMENT, 
    DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF, 
    GET_HEALTH_CATEGORY_LIST, 
    GET_PRE_ASSESSMENT, 
    UPDATE_PRE_ASSESSMENT 
} from '../../constants';


export function getPreAssessmentData(data) {
    return {
        type: GET_PRE_ASSESSMENT,
        data
    };
}

export function addPreAssessmentData(data) {
    return {
        type: ADD_PRE_ASSESSMENT,
        data
    };
}

export function updatePreAssessmentData(data) {
    return {
        type: UPDATE_PRE_ASSESSMENT,
        data
    };
}

export function deletePreAssessmentData(data) {
    return {
        type: DEL_PRE_ASSESSMENT,
        data
    };
}

export function downloadPreArrivalAssessment(data) {
    return {
        type: DOWNLOAD_PRE_ARRIVAL_CONSULTATION_PDF,
        data
    };
}

export function getHealthCategoriesList(data) {
    return {
    type: GET_HEALTH_CATEGORY_LIST,
        data
    };
}