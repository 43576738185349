import React, { useEffect, useRef, useState } from 'react'
import './VideoComponent.scss'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { app } from '../../firebase/firebaseConfig'
import playBtn from '../../assets/images/play.svg'
import { logger } from '../../utils/logging'

function VideoComponent({src, fetch=true}) {
    const vidRef = useRef(null);
    const [videoURL, setVideoURL] = useState(null);

    
    const handleFullScreen=()=>{
        const elem = vidRef.current
        if(elem){ //checking if ref was initiated
            elem?.play()
            if (elem?.requestFullscreen) {
            elem?.requestFullscreen();
            } else if (elem?.webkitRequestFullscreen) { /* Safari */
            elem?.webkitRequestFullscreen();
            } else if (elem?.msRequestFullscreen) { /* IE11 */
            elem?.msRequestFullscreen();
            }
        }
    }

    useEffect(()=>{
        if(fetch){
          if(src && src.length){
            try{
              const storage = getStorage(app);
              const imageRef = ref(storage, src);
        
              getDownloadURL(imageRef).then((url) => {
                  setVideoURL(url);
              }).catch((error) => {
                console.error("Error getting download URL: ", error);
              });
            }catch(e){
              logger.log(e)
            }
          }
        }else{
          if(src){
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              setVideoURL(reader.result);
            });
            reader.readAsDataURL(src);
          }
        }
      },[src])

    useEffect(()=>{
        window.addEventListener('fullscreenchange', ()=>{
            const elem = vidRef.current

            if (document.exitFullscreen || document.webkitExitFullscreen || document.msExitFullscreen) { 
                vidRef?.current?.pause?.()
            }
            
            if ((document.webkitIsFullScreen  || document.mozFullScreen  || document.msFullscreenElement) && vidRef.current) { /* IE11 */
                vidRef.current.currentTime = 0;
                vidRef.current?.play?.()
            }
        })

        return(()=>{
            window.removeEventListener('fullscreenchange',()=>{
                
            })
        })
    }, [])

  return (
    <div className='video-box'>
        <img className='play-btn' src={playBtn} alt="" onClick={handleFullScreen} />
        {src && <video ref={vidRef} src={videoURL} alt=""  width={"100%"} ></video>}
    </div> 
  )
}

export default VideoComponent