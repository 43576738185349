//https://www.meticulous.ai/blog/getting-started-with-react-logging

import { env } from "../const/constants";

// export class ConsoleLogger {
//     log;
//     warn;
//     error;
  
//     constructor() {
//       this.log = console.log.bind(console);
//       this.warn = console.warn.bind(console);
//       this.error = console.error.bind(console);
//     }
//   }

/** Logger which outputs to the browser console */
const NO_OP = (message, ...optionalParams) => {};

class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      this.warn = NO_OP;
      this.log = NO_OP;
      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      this.log = NO_OP;
      return;
    }

    this.log = console.log.bind(console);
  }
}

let logLevel = process.env.REACT_APP_ENV === env.PROD? 'warn' : 'log' 
export const logger = new ConsoleLogger({ level: logLevel });
