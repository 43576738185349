import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_PASSWORD, ENTER_PASSWORD} from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";
import { logger } from "../../../../utils/logging";
const { detect } = require('detect-browser');
const browser = detect();

export const createPasswordReq = async({data})=>{
    const BODY = {
            "user_id": data.user_id,
            "user_auth_id": data.user_auth_id,
            "language": "en",
            "device_token": "#",
            "device_type": "laptop",
            "device_model": "macOS",
            "os_version": browser.os,
            "device_ip": "160.02.300.01",
            "password": data.password,
            "confirm_password": data.confirmPassword
          }

    return axiosInstance.post(apiEndpoints.CREATE_PASSWORD_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                notify("error", errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* createPassword({data}){
    try{
        let result = yield call(createPasswordReq, {data})
        if(result.statusCode === 200){
            localStorage.setItem("UDID", result.data.access_token)
            localStorage.setItem("UserData", JSON.stringify(result.data))
            localStorage.setItem("IsLogin", true)
            data?.onSuccessCallback?.()
            notify('success', result.message)
    }
    }catch(error){
        logger.log(error)
    }
}


export const enterPasswordReq = async({data})=>{
    const BODY = {
            "email_id": data.email,
            "password": data.password,
            "language": "en",
            "device_token": "#",
            "device_type": "laptop",
            "device_model": "macOS",
            "os_version": browser.os,
            "device_ip": "160.02.300.01",
          }

    return axiosInstance.post(apiEndpoints.LOGIN_WITH_PASSWORD_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log(errors)
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                notify("error", errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* enterPassword({data}){
    try{
        let result = yield call(enterPasswordReq, {data})
        if(result.statusCode === 200){
            localStorage.setItem("IsLogin", true)
            localStorage.setItem("UDID", result.data.access_token)
            localStorage.setItem("UserData", JSON.stringify(result.data))
            data?.onSuccessCallback?.()
            notify('success', result.message)
    }
    }catch(error){
        logger.log(error)
    }
}


function* PasswordSaga(){
    yield takeEvery(CREATE_PASSWORD, createPassword)
    yield takeEvery(ENTER_PASSWORD, enterPassword)
}

export default PasswordSaga;