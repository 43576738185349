import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form} from 'formik'
import FormLayout from '../../components/From Layout/FormLayout'
import InputBox from '../../components/Form Components/InputBox/InputBox'
import emailIcon from '../../assets/images/email.svg'
import Button from '../../components/Form Components/Button'
import { emailValidation } from '../../validationSchema/emailMobileSchema'
import { login } from '../../redux/actions/AuthActions/LoginAction'
import "./ForgotPassword.scss"
import * as routes from '../../routes/route'
import {ReactComponent as Arrow} from "../../assets/images/arrow-left.svg"
import { formTypes } from '../../const/constants'
import { forgotPassword } from '../../redux/actions/AuthActions/ForgotPasswordAction'


function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
 

  return (
    <div className="forgot-password-form">
      <FormLayout title="Forgot Password?">
      
        <div className="muted-text">
          Please enter your registered email address, We will sent you an OTP for verification.
        </div>

        <Formik
          initialValues={{ email: ''}}
          validationSchema={emailValidation}
          onSubmit={async(values,  { resetForm })=>{
            resetForm()
            dispatch(forgotPassword({
              "otp_for": "email",
              "email_id": values.email,
              "type": formTypes.FORGOT_PASSWORD,
              navigate,
              onSuccessCallback: ()=>{
                navigate(routes.VERIFICATION_ENDPOINT, {state: {type: formTypes.FORGOT_PASSWORD}})
              }
            }))                         
          }}
        >
          {
            (formik)=>{
              return (
                <Form name="login">
                  <div className="input-container">
                    <InputBox type="email" name="email" placeholder="Email Address" icon={emailIcon}/>
                  </div>

                  <div className="navigation">
                    <div className="text-end">
                      {/* <Link end="true" to={routes.LOGIN_WITH_MOBILE_ENDPOINT}>Login via Mobile Number</Link> */}
                    </div>
                    <div className="text-center">
                      <div className='d-flex align-items-center justify-content-between mt-4'>
                        <div className='text-primary fw-semi-bold d-flex align-items-center cursor-pointer' onClick={()=>navigate(-1)}><Arrow fill='#0d6efd' width={'13px'}/> &nbsp; Back</div>
                        <Button content="continue" type="submit" arrow="right" disabled={!(formik.dirty && formik.isValid)}/>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </FormLayout>
    </div>
  )
}

export default ForgotPassword