import React, { useEffect } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditPhysioDepartureConsultation.scss'
import { ADD, PhysioArrivalConsultationJSON, PhysioDepartureConsultationField, PhysioDepartureConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import { getConsultationNotes } from '../../../../../redux/actions/GuestDataActions/ConsultationNotesActions'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, getDateObjectFromTimeString, parseJSON } from '../../../../../utils/helpers/universalFunctions'
import { TimeSelector } from '../../../../../components/TimeSelector/TimeSelector'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'
import { ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import RadioButton from '../../../../../components/RadioButton/RadioButton'
import targetIcon from '../../../../../assets/images/target.svg'
import Select from "react-select";

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="d-flex flex-wrap align-items-stretch justify-content-start" key={content?.data?.programme_and_treatment_plan?.objectives?.length}>
            {
                content?.data?.programme_and_treatment_plan?.objectives?.map((subitem, subindex)=> {
                    return (
                        <div className="objective-box p-2" key={subindex}>
                            <div className='border rounded'>
                                <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                    <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>
                                    <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                        <RemoveBtn/>
                                    </div>
                                </div>
                                <div className="p-3">
                                {
                                    PhysioDepartureConsultationField?.objectives?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="title mt-2">{item.label}</div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["programme_and_treatment_plan", "objectives", subindex, item.key], value)}
                                                        row={2}
                                                        value={content?.data?.programme_and_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className="p-2 w-50 "  onClick={handleAddMore}>
                <div className="add-more ">
                    Add More Objectives
                </div>
            </div>
        </div>
    )
}

export const AddEditPhysioDepartureConsultation = ({content, setContent, conductedBy, formType,  guestId, userProgramId, guestName, uploadComponent})=>{
    const dispatch = useDispatch()
    const {consultationNotesData, programmeName, followUpTimeline, loading} = useSelector(state=> state.consultationNotesReducer)
    const programList = useSelector(state=> state.programReducer.programList)


  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const handleAddMoreInRecommendation = (key) => {
        setContent(prev => {
            let updatedData = { ...prev }
            updatedData.data.recommendations_and_follow_ups[key] = [...updatedData.data.recommendations_and_follow_ups[key], PhysioDepartureConsultationJSON.data.recommendations_and_follow_ups[key][0]]
            return updatedData;
        })
    }

    const removeInRecommendation = (key, index) => {
        setContent(prev => {
            const updatedData = {
                ...prev,
                data: {
                    ...prev.data,
                    recommendations_and_follow_ups: {
                        ...prev.data.recommendations_and_follow_ups,
                        [key]: prev.data.recommendations_and_follow_ups[key].filter((_, i) => i !== index)
                    }
                }
            };
            return updatedData;
        });
    }


    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.programme_and_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }

    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.programme_and_treatment_plan.objectives = [...updatedData.data.programme_and_treatment_plan.objectives, PhysioArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    useEffect(()=>{
        if(guestId && userProgramId && formType===ADD){
            dispatch(getConsultationNotes({guestId, userProgramId, noteType: "Physio Arrival Consultation"}))
        }
      },[])

    useEffect(()=>{
        let notes_detail = parseJSON(consultationNotesData?.[0]?.list?.[0]?.notes_detail);
        if(formType===ADD){ //have dummy JSON
        setContent(prev=>{
            let newData= {...prev};
            newData.date = moment().format("YYYY-MM-DD")

            if(notes_detail){
                newData.data.programme_and_treatment_plan = {
                    "objectives": notes_detail?.data?.summary_assessment_treatment_plan?.objectives
                }
                
                let presentation_of_pain = notes_detail?.data?.pain_symptom?.presentation_of_pain
                let effect_of_activity_on_pain = notes_detail?.data?.effect_of_activity_on_pain
                let nature_of_pain = notes_detail?.data?.nature_of_pain
                let perceived_ease_of_movement = notes_detail?.data?.perceived_ease_of_movement
    
                newData.data.health_assessment.presentation_of_pain.arrival_reading = presentation_of_pain
                newData.data.health_assessment.effect_of_activity_on_pain.arrival_reading = effect_of_activity_on_pain
                newData.data.health_assessment.nature_of_pain.arrival_reading = nature_of_pain
                newData.data.health_assessment.perceived_ease_of_movement.arrival_reading = perceived_ease_of_movement
    
                newData.data.health_assessment.presentation_of_pain.value = presentation_of_pain
                newData.data.health_assessment.effect_of_activity_on_pain.value = effect_of_activity_on_pain
                newData.data.health_assessment.nature_of_pain.value = nature_of_pain
                newData.data.health_assessment.perceived_ease_of_movement.value = perceived_ease_of_movement
    
                
                for (let key in notes_detail.data.pain_and_functional_assessment) {
                    newData.data.functional_assessment[key].arrival_reading = capitalize(notes_detail?.data.pain_and_functional_assessment[key] || "")
                    newData.data.functional_assessment[key].value = capitalize(notes_detail?.data.pain_and_functional_assessment[key] || "")
                }
    
                newData.data.functional_assessment.gait_assessment=[]
    
                notes_detail.data?.functional_assessment?.gait_assessment?.forEach((item)=>{
                    newData?.data?.functional_assessment?.gait_assessment.push({arrival_reading: item, value: item})
                })
    
                newData.data.functional_assessment.posture=[]
    
                notes_detail.data?.functional_assessment?.posture?.forEach((item)=>{
                    newData?.data?.functional_assessment?.posture.push({arrival_reading: item, value: item})
                })
            }

            return newData;
        })
        }
    },[consultationNotesData])

    return (
      <div className='physio-departure-consultation-form'>
        {
          loading ?
          <div className="loader mx-auto">
            <CircularProgress sx={{'color': '#74613C'}} size={20}/>
          </div>
          :
          <>
            <>
            <div className='text-danger border border-danger rounded py-2 fw-semi-bold text-center my-2'>
                THIS ENTIRE FORM WILL BE AUTOMATICALLY VISIBLE TO GUEST ON SUBMISSION
            </div>
            <div className="d-flex py-4 mb-2 justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Conducted By: </div>
                    {/* <TextField
                        width={'300px'}
                        type={'text'}
                        placeholder='Enter name' 
                        value={content?.conducted_by || ''} 
                        onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                    /> */}
                    {conductedBy || '-'}
                </div>
                <div className="d-flex align-items-center">
                    <div className="title text-secondary me-3">Date</div>
                    <DatePicker
                    value={content.date ? new Date(content.date) : ''}
                    onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                    width={'300px'}
                    />
                </div>
                </div>
                <div className=" w-100 rounded mb-4">
                    <div className="fw-semi-bold">
                        Dear {guestName},
                    </div>
                    Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Yoga Consultant during post-stay online follow-ups.  
                    </div>                
                    <div className="border w-100 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    PROGRAMME & TREATMENT PLAN
                    </div>
                    <div className='p-3'>
                    <div className='fw-semibold mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>

                    <Objectives
                        content={content}
                        removeObjective={removeObjective}
                        setKey={setKey}
                        handleAddMore={handleAddMore}
                    />
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    HEALTH ASSESSMENT
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Arrival
                            </div>
                            <div className='col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                PhysioDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 " key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4 mt-2'>
                                                {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='col-5 pb-4'>
                                            {
                                                item.options?.map((subItem, subIndex)=>{
                                                    return (
                                                        <div className='d-flex mb-1' key={subIndex}>
                                                            <RadioButton
                                                                label={subItem}
                                                                name={item.key}
                                                                value={subItem}
                                                                checked={content?.data?.health_assessment?.[item.key]?.value===subItem}
                                                                onChange={(e)=>{setKey(["health_assessment", item.key, "value"], e.target.value)}}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded healthassessment">
                    <div className="heading py-3">
                    FUNCTIONAL ASSESSMENTS
                    </div>
                    <div className='p-3'>
                        <div className="row fw-semibold pb-2 border-bottom w-100 mb-3" >
                            <div className="mt-2 col-3">Parameter</div>
                            <div className='col-4'>
                                On Arrival
                            </div>
                            <div className='col-5'>
                                On Departure
                            </div>
                        </div>
                            {
                                PhysioDepartureConsultationField?.functionalAssessment?.map((item, index)=>{
                                    return (
                                        <div className="row w-100 " key={index}>
                                            <div className="title mt-2 col-3">{item.label}</div>
                                            <div className='col-4 mt-2'>
                                                {content?.data?.functional_assessment?.[item.key]?.arrival_reading || '-'}
                                            </div>
                                            <div className='col-5'>
                                                <TextArea
                                                    row={2}
                                                    placeholder={"Enter "}
                                                    onChange={value =>setKey(["functional_assessment", item.key, "value"], value)}
                                                    value={content?.data?.functional_assessment?.[item.key]?.value || ''}/>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="row w-100 ">
                                <div className="title mt-2 col-3">Gait (if applicable)</div>
                                    {
                                        content?.data?.functional_assessment?.gait_assessment?.map((item, index)=>{
                                            return (
                                                <React.Fragment key={index}>
                                                    {index!==0 && <div className='col-3'></div>}
                                                    <div className='col-4 mt-2'>
                                                        <span className="text-muted fw-semi-bold">{(index+1).toString().padStart(2, 0)}.</span> &nbsp; {content?.data?.functional_assessment?.gait_assessment[index]?.arrival_reading || '-'}
                                                    </div>
                                                    <div className='col-5 d-flex align-items-center'>
                                                        <div className="flex-grow-1">
                                                            <TextArea
                                                                className={'flex-grow-1'}
                                                                row={2}
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["functional_assessment", "gait_assessment", index, "value"], value)}
                                                                value={content?.data?.functional_assessment?.gait_assessment[index]?.value || ''}/>
                                                        </div>
                                                            {/* {
                                                                !item.arrival_reading && <div className='remove-btn ms-2' onClick={() => {removeInRecommendationWithKey("gait_assessment", index)}}>
                                                                    <RemoveBtn />
                                                                </div>
                                                            } */}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    {/* <div className='cursor-pointer mx-auto mt-2 mb-3' style={{width: 'fit-content'}} onClick={() => {handleAddMoreInRecommendationWithKey("gait_assessment")}}>
                                        <Add />
                                    </div> */}
                                    
                            </div>
                            <div className="row w-100 ">
                                <div className="title mt-2 col-3">Posture (if applicable)</div>
                                    {
                                        content?.data?.functional_assessment?.posture?.map((item, index)=>{
                                            return (
                                                <React.Fragment key={index}>
                                                    {index!==0 && <div className='col-3'></div>}
                                                    <div className='col-4 mt-2'>
                                                        <span className="text-muted fw-semi-bold">{(index+1).toString().padStart(2, 0)}.</span> &nbsp; {content?.data?.functional_assessment?.posture[index]?.arrival_reading || '-'}
                                                    </div>
                                                    <div className='col-5 d-flex align-items-center'>
                                                        <div className="flex-grow-1">
                                                            <TextArea
                                                                className={'flex-grow-1'}
                                                                row={2}
                                                                placeholder={"Enter "}
                                                                onChange={value =>setKey(["functional_assessment", "posture", index, "value"], value)}
                                                                value={content?.data?.functional_assessment?.posture[index]?.value || ''}/>
                                                        </div>
                                                            {/* {
                                                                !item.arrival_reading && <div className='remove-btn ms-2' onClick={() => {removeInRecommendationWithKey("posture", index)}}>
                                                                    <RemoveBtn />
                                                                </div>
                                                            } */}
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                    {/* <div className='cursor-pointer mx-auto mt-2 mb-3' style={{width: 'fit-content'}} onClick={() => {handleAddMoreInRecommendationWithKey("posture")}}>
                                        <Add />
                                    </div> */}
                                    
                            </div>
                    </div>
                </div>
                <div className="border w-100 mt-5 rounded systematic">
                    <div className="heading py-3">
                        RECOMMENDATIONS AND FOLLOW UPS
                    </div>
                    <div className='p-3'>
                        <div className="row m-0 fw-semibold pb-2 border-bottom mb-3" >
                            <div className="col-2">Daily Routine</div>
                            <div className="col-4">Details</div>
                            <div className="col-2">Time of Practice</div>
                            <div className='col-2'>
                            Duration/Repetition 
                            </div>
                            <div className='col-2'>
                                Frequency
                            </div>
                        </div>
                            {
                                PhysioDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                    return (
                                        <React.Fragment key={index}>
                                            {
                                                item?.isArray ?
                                                <div className='title row'>
                                                    <div className="col-12 text-muted fw-semi-bold">
                                                        {item.label}
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        <div key={content?.data?.recommendations_and_follow_ups?.[item.key]?.length}>
                                            {
                                                content?.data?.recommendations_and_follow_ups?.[item.key]?.map((subItem, subindex)=>{
                                                    return (
                                                    <div key={subindex}>
                                                        <div className="row w-100 m-0 mb-2" >
                                                            <div className="title mt-2 col-2"> <span className={`${item.isArray ? 'ps-2' : ''}`}>{item.isArray ? (subindex+1)?.toString()?.padStart(2, '0') : item.label}</span> </div>
                                                            <div className='col-4'>
                                                                <div className="data-input">
                                                                    <TextArea
                                                                        row={2}
                                                                        placeholder={"Enter "}
                                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "details"], value)}
                                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.details || ''}/>
                                                                </div>
                                                            </div>
                                                            <div className='col-2 pt-2'>
                                                                <TimeSelector
                                                                    width={'100%'}
                                                                    ampm={true}
                                                                    time={getDateObjectFromTimeString(content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.time) || ''}
                                                                    setTime={(value)=>setKey(["recommendations_and_follow_ups", item.key, subindex, "time"], moment(new Date(value)).format('HH:mm A')) }
                                                                    
                                                                />
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className="data-input">
                                                                    <TextArea
                                                                        row={2}
                                                                        placeholder={"Enter "}
                                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "duration"], value)}
                                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.duration || ''}/>
                                                                </div>
                                                            </div>
                                                            <div className='col-2'>
                                                                <div className="data-input  d-flex align-items-center">
                                                                    <TextArea
                                                                        row={2}
                                                                        placeholder={"Enter "}
                                                                        onChange={value =>setKey(["recommendations_and_follow_ups", item.key, subindex, "frequency"], value)}
                                                                        value={content?.data?.recommendations_and_follow_ups?.[item.key][subindex]?.frequency || ''}/>
                                                                    {
                                                                        item.isArray && <div className='remove-btn ms-2' onClick={() => {removeInRecommendation(item.key, subindex)}}>
                                                                        <RemoveBtn />
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                    </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>

                                        {
                                            item.isArray &&
                                            <div className='cursor-pointer mx-auto mb-5' style={{width: 'fit-content'}} onClick={() => {handleAddMoreInRecommendation(item.key)}}>
                                                <Add />
                                            </div>
                                        }
                                        </React.Fragment>
                                    )
                                })
                            }
                    </div>
                </div>

                <div className="border w-100 mt-5 mb-3 rounded current-dietary-pattern ">
                    <div className="heading py-3">
                    FOLLOW UP PROGRAMME
                    </div>
                    <div className=' '>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "consultation"], value)}
                                    value={content?.data?.follow_up_programme?.consultation || ''}/>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <Select
                                    className="name-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={
                                        programList ? programList?.map(item=> ({
                                          label: item.title,
                                          value: item.title
                                        }))
                                        : []
                                    }
                                    placeholder='Select'
                                    onChange={e =>setKey(["follow_up_programme", "programme"], e.value)}
                                />
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                            <div className="text-break flex-grow-1 border p-2 white-space">
                                <TextArea
                                    row={2}
                                    placeholder={"Enter "}
                                    onChange={value =>setKey(["follow_up_programme", "timeline"], value)}
                                    value={content?.data?.follow_up_programme?.timeline || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <div className="w-50">
            {uploadComponent}
            </div>
          </>
        }
      </div>
    )
}