import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "./../../../axios/axiosInstance";
import { notify } from "./../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";
import { getFirebaseURL } from "../../../firebase/firebaseFunction";
import { logger } from "../../../utils/logging";


const getUserManualApi = async () => {
    try {
        const response = await axiosInstance.get(apiEndpoints.GET_USER_MANUALS_API);
            response.data = await Promise.all(response.data.map(async manual => {
            let manuals_url= await getFirebaseURL(manual.doc_path)
            return {
                ...manual,
                url: manuals_url 
            };
        }))

        return response;
    } catch (errors) {
        logger.log("errors", errors)
        if (
            errors.response.data.statusCode === 400) {
            notify('error', errors.response.data.message);
            return
        } if (errors.response.data.statusCode === 401) {
            logOut('error', errors.response.data.message);
            notify('error', errors.response.data.message);
        } else {
            notify('warning', "Something went wrong");
        }
        return errors
    }
}
function* getUserManual(action) {
    try {
        const response = yield call(getUserManualApi);
        if (response?.statusCode === 200) {
            yield put({ type: types.GET_USER_MANUAL_DATA_SUCCESS, payload: response.data });
        } else {
            if (response?.statusCode === 401) {
                logOut('error', response?.message)
            } else {
                notify('error', response?.message);
                yield put({ type: types.GET_USER_MANUAL_DATA_FALIURE, message: response?.message });
            }
        }
    } catch (e) {
        logger.log(e)
        yield put({ type: types.GET_USER_MANUAL_DATA_FALIURE});
    }
}

function* HelpSaga(){
    yield takeEvery(types.GET_USER_MANUAL_DATA, getUserManual)
}

export default HelpSaga;