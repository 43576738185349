import { call, put, takeEvery } from "redux-saga/effects";
import axiosInstance from "../../../axios/axiosInstance";
import { notify } from "../../../utils/notification";
import {logOut} from '../../../utils/helpers/logout'
import * as types from "../../constants";
import apiEndpoints from "../../../axios/api";
import { logger } from "../../../utils/logging";

export const getQueryListReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_QUERY_LIST_API}?filter=${data.assignedTo}&flag=${data.flag}&page_no=${data?.page}${data.guestId ? `&guest_id=${data.guestId}` : ''}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getQueryList({data}){
    try{
        let result = yield call(getQueryListReq, {data})
        if(result.statusCode === 200){
            if(data.flag === 1){
                yield put({ type: types.SET_ACTIVE_QUERY, payload: result.data, data: data});
            }else if (data.flag === 0){
                yield put({ type: types.SET_CLOSED_QUERY, payload: result.data, data});
            }
        }else{
            yield put({type: types.GET_QUERY_LIST_FALIURE, data: data})
        }
        
    }catch(error){
        logger.log(error)
    }
}

export const getQueryMessageReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_QUERY_MESSAGE_API}?chat_id=${data.chatId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getQueryMessage({data}){
    try{
        let result = yield call(getQueryMessageReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_QUERY_MESSAGE_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_QUERY_MESSAGE_FALIURE})
        }
        
    }catch(error){
        logger.log(error)
    }
}

export const sendMessageInQueryReq = async({data})=>{
    const BODY = {
        "chat_id": data.chatId,
        "message_type": data.messageType,
        "message": data.message,
        "datetime": data.datetime
      }

      return axiosInstance.post(apiEndpoints.SEND_MESSAGE_IN_QUERY_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* sendMessageInQuery({data}){
    logger.log(data)
    try{
        let result = yield call(sendMessageInQueryReq, {data})

        if(result.statusCode === 200){
            data?.onSuccessCallback?.()
            let result3 = yield call(getQueryMessageReq, {data})
            yield put({ type: types.SEND_MESSAGE_IN_QUERY_SUCCESS, payload: { messages: result3.data}});            
        }    
    }catch(error){
        logger.log(error)
    }
}


export const getQueryDetailsReq = async({data})=>{
    return axiosInstance.get(`${apiEndpoints.GET_QUERY_DETAILS_API}?chat_id=${data.chatId}`)
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* getQueryDetails({data}){
    try{
        let result = yield call(getQueryDetailsReq, {data})
        if(result.statusCode === 200){
            yield put({ type: types.GET_QUERY_DETAILS_SUCCESS, payload: result.data});
        }else{
            yield put({type: types.GET_QUERY_DETAILS_FALIURE})
        }
        
    }catch(error){
        logger.log(error)
    }
}


export const updateQueryStatusReq = async({data})=>{
    const BODY = {
        "chat_id": data.chatId,
        "message_type": data.messageType,
        "query_status": data.status,
        "message": data.message,
        "datetime": data.datetime
      }
      return axiosInstance.put(apiEndpoints.UPDATE_QUERY_STATUS_API, { data: BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                logger.log(errors.response.data.message);
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* updateQueryStatus({data}){
    try{
        let result = yield call(updateQueryStatusReq, {data})

        if(result.statusCode === 200){
            data?.onSuccessCallback?.()
            let result2 = yield call(getQueryDetailsReq, {data})
            let result3 = yield call(getQueryMessageReq, {data})
            yield put({ type: types.UPDATE_QUERY_STATUS_SUCCESS, payload: {queryDetails: result2.data, messages: result3.data}});
            notify('success', result.message);
        }
         yield put({ type: types.UPDATE_QUERY_STATUS_FALIURE});

    }catch(error){
        logger.log(error)
    }
}




function* querySaga(){
    yield takeEvery(types.GET_QUERY_LIST, getQueryList);
    yield takeEvery(types.GET_QUERY_MESSAGE, getQueryMessage);
    yield takeEvery(types.SEND_MESSAGE_IN_QUERY, sendMessageInQuery);
    yield takeEvery(types.GET_QUERY_DETAILS, getQueryDetails);
    yield takeEvery(types.UPDATE_QUERY_STATUS, updateQueryStatus);
}

export default querySaga;