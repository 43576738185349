export const LOGIN_WITH_MOBILE_ENDPOINT = "/login/mobile"
export const LOGIN_WITH_EMAIL_ENDPOINT = "/login/email"
export const UPDATE_MOBILE_NUMBER_ENDPOINT = "/update/mobile"
export const VERIFICATION_ENDPOINT = "/verification"
export const NOT_FOUND_ENDPOINT = "/not-found"
export const SEARCH_ENDPOINT = "/search"
export const GUEST_LIST_ENDPOINT = "/guest-list"
export const SEARCH_RESULT_ENDPOINT = "/result"
export const VIEW_PROFILE_ENDPOINT = "/profile"
export const EDIT_PROFILE_ENDPOINT = "/profile/edit"
export const FORGOT_PASSWORD_ENDPOINT = "/forgot-password"
export const CREATE_PASSWORD_ENDPOINT = "/create-password"
export const ENTER_PASSWORD_ENDPOINT = "/enter-password"


export const VIEW_PRELIM_ASSESMENT_ENDPOINT = "/pre-arrival-consultation/view"
export const ADD_PRELIM_ASSESMENT_ENDPOINT = "/pre-arrival-consultation/add"
export const EDIT_PRELIM_ASSESMENT_ENDPOINT = "/pre-arrival-consultation/edit"

export const VIEW_WELLBEING_REPORT_ENDPOINT = "/report/view"
export const PRINT_WELLBEING_REPORT_ENDPOINT = "/report/print-health-assessment-report"
export const VIEW_FULL_WELLBEING_REPORT_ENDPOINT = "/report/full-view"
export const EDIT_WELLBEING_REPORT_ENDPOINT = "/report/edit"
export const REOPEN_REPORT_FOR_GUEST_ENDPOINT = "/report/reopen"


export const VIEW_PREPARATORY_DIET_ENDPOINT = "/preparatory-diet/view"
export const EDIT_PREPARATORY_DIET_ENDPOINT = "/preparatory-diet/edit"

export const VIEW_CONSULTATION_NOTES_ENDPOINT = "/notes/view"
export const ADD_CONSULTATION_NOTES_ENDPOINT = "/notes/add"
export const EDIT_CONSULTATION_NOTES_ENDPOINT = "/notes/edit"

export const VIEW_SESSION_ENDPOINT = "/session/view"
export const VIEW_SESSION_NOTES_ENDPOINT = "/session/view-note"
export const ADD_SESSION_NOTES_ENDPOINT = "/session/add-note"
export const EDIT_SESSION_NOTES_ENDPOINT = "/session/edit-note"

export const QUERY_ENDPOINT = "/queries"
export const CHAT_ENDPOINT = "/chat"


export const GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT = "/group-classess-and-activity"
export const VIEW_GUEST_LIST_ENDPOINT = "/group-classess-and-activity/guest-list"

export const STATUS_REPORT_ENDPOINT = "/status-report"

export const VIEW_TESTIMONIAL_ENDPOINT = "/testimonial/view"
export const VIEW_PROGRAMME_FEEDBACK_ENDPOINT = "/programme-feedback/view"

export const HELP_ENDPOINT = "/help"
export const VIEW_HELP_ENDPOINT = "/user-manual/view"
