import * as type from '../../constants';

const initialState = {
    userManualList: [],
    loading: false,
    error: null,
}

export function getUserManualListReducer(state = initialState, action) {
    switch (action.type) {
        case type.GET_USER_MANUAL_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_USER_MANUAL_DATA_SUCCESS:
            return {
                ...state,
                userManualList: action.payload,
                loading: false,
                error: null
            }
        case type.GET_USER_MANUAL_DATA_FALIURE:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}