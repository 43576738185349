
import { GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT, GUEST_LIST_ENDPOINT, HELP_ENDPOINT, QUERY_ENDPOINT, SEARCH_ENDPOINT, STATUS_REPORT_ENDPOINT } from "../routes/route";
import {ReactComponent as SearchIcon} from '../assets/images/search.svg'
import {ReactComponent as QueriesIcon} from '../assets/images/Queries.svg'
import {ReactComponent as CalenderIcon} from '../assets/images/calender2.svg'
import {ReactComponent as StatusReportIcon} from '../assets/images/status-report.svg'
import {ReactComponent as GuestsIcon} from '../assets/images/guests.svg'
import {ReactComponent as HelpIcon} from '../assets/images/help.svg'

export const homeData = [
    {
        title: "Guests",
        path: GUEST_LIST_ENDPOINT,
        permissionKey: "guest_listing",
         icon: <GuestsIcon/>
     }
    ,
    {
        title: "Group Activity",
        path: GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT,
        permissionKey: "grop_activity_registration",
         icon: <CalenderIcon/>
     }
    ,
    {
        title: "Search",
        path: SEARCH_ENDPOINT,
        permissionKey: "guest_search",
         icon: <SearchIcon/>
     },
    {
        title: "Status Report",
        path: `${STATUS_REPORT_ENDPOINT}?guestStatus=1`,
        permissionKey: "",
         icon: <StatusReportIcon/>
     }
    ,
    {
        title: "Queries",
        path: QUERY_ENDPOINT,
        permissionKey: "wellbeing_queries",
        icon: <QueriesIcon/>
     }
    ,
    {
        title: "Help",
        path: HELP_ENDPOINT,
        permissionKey: "",
        icon: <HelpIcon fontSize={40} />
     }
]