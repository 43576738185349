import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import './AddEditTCMArrivalConsultation.scss'
import { ADD, TCMArrivalConsultationField, TCMArrivalConsultationJSON } from '../../NoteFormsConstants'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import targetIcon from '../../../../../assets/images/target.svg'
import { useEffect } from 'react'

const Objectives = ({setKey, removeObjective, content, handleAddMore})=>{
    return (
        <div className="d-flex flex-wrap align-items-stretch justify-content-start" key={content?.data?.summary_assessment_treatment_plan?.objectives?.length}>
            {
                content?.data?.summary_assessment_treatment_plan?.objectives?.map((subitem, subindex)=> {
                    return (
                        <div className="objective-box p-2" key={subindex}>
                            <div className='border rounded'>
                                <div className="heading p-3 py-2 d-flex justify-content-between align-items-center">
                                    <div>
                                    <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(subindex+1)?.toString()?.padStart(2, 0)}
                                    </div>                                    <div className='remove-btn' onClick={()=>removeObjective(subindex)}>
                                        <RemoveBtn/>
                                    </div>
                                </div>
                                <div className="p-3">
                                {
                                    TCMArrivalConsultationField?.objectives?.map((item, index)=>{
                                        return (
                                            <div className="data-row" key={index}>
                                                <div className="fw-semi-bold mt-2" style={{width: '210px'}}>{item.label}: </div>
                                                <div className='data-input'>
                                                    <TextArea
                                                        row={2}
                                                        placeholder={"Enter "}
                                                        onChange={value =>setKey(["summary_assessment_treatment_plan", "objectives", subindex, item.key], value)}
                                                        value={content?.data?.summary_assessment_treatment_plan?.objectives?.[subindex]?.[item.key] || ''}/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            
            
            <div className="p-2 w-50 "  onClick={handleAddMore}>
                <div className="add-more ">
                    Add More Objectives
                </div>
            </div>
        </div>
    )
}


export const AddEditTCMArrivalConsultation = ({content, setContent, conductedBy, formType, uploadComponent})=>{
  const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData.data);
        return updatedData;
      })
    }

    const removeObjective = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.data.summary_assessment_treatment_plan.objectives.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMore=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.data.summary_assessment_treatment_plan.objectives = [...updatedData.data.summary_assessment_treatment_plan.objectives, TCMArrivalConsultationJSON.data.summary_assessment_treatment_plan.objectives[0]]
            return updatedData;
        })
    }

    useEffect(()=>{
        if(formType===ADD){
            setContent(prev=>{
                let newData = {...prev}
                newData.date = moment().format("YYYY-MM-DD")
                return newData
            })
        }
    }, [])


    return (
      <div className='tcm-arrival-consultation-form'>
          <>
          <div className="d-flex py-2 mb-3 rounded justify-content-between">
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Conducted By: </div>
                {/* <TextField
                    width={'300px'}
                    type={'text'}
                    placeholder='Enter name' 
                    value={content?.conducted_by || ''} 
                    onChange={e=>setContent( {...content, 'conducted_by' : e.target.value})}
                  /> */}
                  {conductedBy || '-'}
              </div>
              <div className="d-flex align-items-center">
                <div className="title text-secondary me-3">Date</div>
                <DatePicker
                  value={content.date ? new Date(content.date) : ''}
                  onChange={date=>setContent( {...content, 'date' : moment(date).format('YYYY-MM-DD')})}
                  width={'300px'}
                />
              </div>
            </div>
            <div className="border w-100 mt-3 rounded systematic">
                <div className="heading py-3">
                    SYSTEMIC  ASSESSMENT
                </div>
                <div className='p-3'>
                    <div className="sub-heading my-2">
                        Respiratory System:
                    </div>
                    <div className='py-2'>
                        <div className="data-row">
                            <div className="title mt-2">Lung Field: </div>
                            <div className='data-input'>
                                <TextArea
                                    row={3}
                                    placeholder={"Remarks "}
                                    onChange={value =>setKey(["systemic_assessment", "respiratory_system", "lung_field"], value)}
                                    value={content?.data?.systemic_assessment?.respiratory_system?.["lung_field"] || ''}/>
                            </div>
                        </div>
                        <div className="data-row">
                            <div className="title mt-2">Auscultation: </div>
                            <div className='data-input'>
                                <TextArea
                                    row={3}
                                    placeholder={"Remarks "}
                                    onChange={value =>setKey(["systemic_assessment", "respiratory_system", "auscultation"], value)}
                                    value={content?.data?.systemic_assessment?.respiratory_system?.["auscultation"] || ''}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-3'>
                    <div className="sub-heading">
                    Cardiovascular System:
                    </div>
                    <div className='pt-3'>
                        <div className="data-row mb-2">
                            <div className="title mt-1">Pluse Rate: </div>
                            <div className='data-input  w-50'>
                                <TextField 
                                    type={'item.type'}
                                    placeholder='Enter value' 
                                    value={content?.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.value || ''} 
                                    onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "pulse_rate", "value"], e.target.value)}
                                    unit={content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.unit}
                                    />

                            </div>
                        </div>
                        <div className="data-row mb-2">
                            <div className="title mt-1">Blood Pressure: </div>
                            <div className='data-input  w-50'>
                                <TextField 
                                    type={'item.type'}
                                    placeholder='Enter value' 
                                    value={content?.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.value || ''} 
                                    onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "blood_pressure", "value"], e.target.value)}
                                    unit={content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.unit}
                                    />

                            </div>
                        </div>
                        <div className="data-row mb-3">
                            <div className="title mt-1">SpO<sub>2</sub>:</div>
                            <div className='data-input w-50'>
                                <TextField 
                                    type={'item.type'}
                                    placeholder='Enter value' 
                                    value={content?.data?.systemic_assessment?.cardiovascular_system?.spo2?.value || ''} 
                                    onChange={e =>setKey(["systemic_assessment", "cardiovascular_system", "spo2", "value"], e.target.value)}
                                    unit={content.data?.systemic_assessment?.cardiovascular_system?.spo2?.unit}
                                    />

                            </div>
                        </div>
                        <TextArea
                            row={3}
                            placeholder={"Remarks "}
                            onChange={value =>setKey(["systemic_assessment", "cardiovascular_system", "remarks"], value)}
                            value={content?.data?.systemic_assessment?.cardiovascular_system?.remarks || ''}
                        />
                    </div>
                </div>
                <div className='p-2'>
                    <div className="sub-heading">
                        Musculoskeletal system:
                    </div>
                    <div className='text-muted'>
                        Region, Pain, tenderness, ROM
                    </div>
                    <div className='pt-2'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["systemic_assessment", "musculoskeletal_system"], value)}
                            value={content?.data?.systemic_assessment?.musculoskeletal_system || ''}
                        />
                    </div>
                </div>
                <div className='p-2'>
                    <div className="sub-heading">
                        Skin:
                    </div>
                    <div className='py-1'>
                        <TextArea
                            row={3}
                            placeholder={"Enter "}
                            onChange={value =>setKey(["systemic_assessment", "skin"], value)}
                            value={content?.data?.systemic_assessment?.skin || ''}
                        />
                    </div>
                </div>
                <div className='p-3'>
                    <div className="sub-heading mb-2">
                    TCM DOCTOR'S EXAMINATION
                    </div>
                    {
                        TCMArrivalConsultationField?.doctorExamination?.map((item, index)=>{
                            return (
                                <div className="data-row">
                                    <div className="title mt-2">{item.label}: </div>
                                    <div className='data-input'>
                                        <TextArea
                                            row={3}
                                            placeholder={"Remarks "}
                                            onChange={value =>setKey(["systemic_assessment", "doctor_examination", item.key], value)}
                                            value={content?.data?.systemic_assessment?.doctor_examination?.[item.key] || ''}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className='text-danger border border-danger rounded py-2 fw-semi-bold mt-5 text-center mb-2'>
                THIS SECTION OF THE FORM WILL BE AUTOMATICALLY VISIBLE TO GUEST ON SUBMISSION
            </div>

            <div className="border w-100 rounded summary">
                <div className="heading py-3">
                    SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='p-3'>
                    <div className="sub-heading py-4">
                        Primary objectives for improvement
                    </div>

                    <Objectives
                        content={content}
                        removeObjective={removeObjective}
                        setKey={setKey}
                        handleAddMore={handleAddMore}
                    />
                    
                </div>

            </div>
          </>
          <div className="w-50">
          {uploadComponent}
          </div>
      </div>
    )
}