import moment from 'moment'
import { DEPARTURE, LabTestsField, LabTestsJSON, OldLabTestsField } from '../NoteFormsConstants'
import { useEffect, useRef, useState } from 'react'
import { parseJSON } from '../../../../utils/helpers/universalFunctions';
import dotIcon from '../../../../assets/images/3dots.svg'
import editBtn from '../../../../assets/images/edit.svg'
import deleteBtn from '../../../../assets/images/delete.svg'
import './ViewLabTests.scss'
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { deleteConsultationNotes } from '../../../../redux/actions/GuestDataActions/ConsultationNotesActions';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { EDIT_CONSULTATION_NOTES_ENDPOINT } from '../../../../routes/route';
import ChangeVisibilityModal from '../../../../components/ChangeVisibilityModal/ChangeVisibilityModal';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import {ReactComponent as FileIcon} from '../../../../assets/images/file.svg'
import arrowDownIcon from '../../../../assets/images/arrow-down-brown.svg'

export const ViewLabTests = ({content, guestId, userProgramId})=>{
    const [title, setTitle] = useState([]);
    const [data, setData] = useState([]);
    const [oldData, setOldData] = useState([]);
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const canWriteNote = (permissions?.consultation_notes?.permissions || '').includes('write')
    const canWriteDietNote = (permissions?.dietary_notes?.permissions || '').includes('write')
  

    useEffect(()=>{
        if(content){
            let copyContent=JSON.parse(JSON.stringify(content))
            const seen = new Set();
            let titleList = []
            
            let updateOldData=[]
            let updateData=[]
            copyContent.forEach((item, index)=>{
                const notesDetail = item.notes_detail ? parseJSON(item.notes_detail) : null;
                item.notes_detail = notesDetail;  

                if(!notesDetail?.data?.static_fields){
                    updateOldData = [...updateOldData,  item]
                    return;
                }
                
                //changing format from array to object
                let updatedDynamicField = {}
                const dynamicFields = item.notes_detail?.data.dynamic_fields.map(field => {
                    updatedDynamicField[`${field?.label}`] = field;

                    return {
                    label: field?.label,
                    order_by: field?.order_by,
                    unit: field?.unit
                }});
                item.notes_detail.data.dynamic_fields = updatedDynamicField;
                //
                

                //adding all title to a state
                [...dynamicFields].forEach(field => {
                    const fieldIdentifier = `${field?.label}|${field?.key}`;
        
                    if (!seen.has(fieldIdentifier)) {
                        titleList.push(field);
                        seen.add(fieldIdentifier); 
                    }
                });

                updateData=[...updateData, item]
            })
            
            setOldData(updateOldData)
            setData(updateData)
            titleList.sort((a, b) => a.order_by - b.order_by);
            setTitle(titleList)
        }
    }, [content])
    
    return (
        <>
            {
                !!data.length &&
                <div className="my-3 rounded-3 border border-2" style={{ overflow: 'auto', maxHeight: '500px', borderWidth: '2px'}}>
                    <table className='m-0' style={{ width: '100%', tableLayout: 'fixed', borderSpacing: 0, borderCollapse: 'separate'}}>
                        <thead className='position-sticky  top-0 rounded' style={{backgroundColor: '#F8F9FA', zIndex: 1}}>
                            <tr>
                                <th className='align-top p-3 py-2 position-sticky top-0 border-end start-0' style={{backgroundColor: '#F8F9FA', width: '200px', zIndex: 3}}>Conducted By: </th>
                                {
                                    data.map((item, index)=>{
                                        return(
                                            <th key={index} className='p-3 text-break py-2 fw-normal' style={{width: '200px'}}>{`${data[index].first_name} ${data[index].last_name}` || '-'}</th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                <th className='align-top p-3 py-2 pt-0 border-bottom position-sticky top-0 border-end start-0' style={{backgroundColor: '#F8F9FA', width: '200px', zIndex: 3}}>Date: </th>
                                {
                                    data.map((item, index)=>{
                                        const canWrite = (item.notes_type === "Dietary Notes" ? canWriteDietNote : canWriteNote) && item?.user_id === userData?.user_id
                                        return(
                                            <th key={index} className='p-3 py-2 fw-normal border-bottom' style={{width: '200px'}}>
                                                {data[index].notes_detail.date ? moment(data[index].notes_detail.date, 'YYYY-MM-DD').format('DD MMM, YYYY') : '-'}
                                                {canWrite && <ViewOptions note={item} guestId={guestId} userProgramId={userProgramId} />}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                LabTestsField?.map((item, index)=>{
                                    return (
                                        <tr className='border-bottom' key={index}>
                                            <td className='align-top p-3 py-2 fw-semi-bold border-end position-sticky start-0' style={{ backgroundColor: '#F8F9FA' }}>{item.label || '-'}</td>
                                            {
                                                data.map((subItem, subIndex)=>{
                                                    return(
                                                        <td key={subIndex} className='p-3 py-2 fw-normal text-break border-start border-end'>
                                                            {
                                                                data[subIndex].notes_detail.data.static_fields[item.key] ?
                                                                `${data[subIndex].notes_detail.data.static_fields[item.key]} ${item.unit}`
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                            {
                                title?.map((item, index)=>{
                                    return (
                                        <tr className='border-bottom' key={index}>
                                            <td className='align-top p-3 py-2 fw-semi-bold border-end position-sticky text-break start-0' style={{ backgroundColor: '#F8F9FA' }}>{item.label || '-'}</td>
                                            {
                                                data.map((subItem, subIndex)=>{
                                                    return(
                                                        <td key={subIndex} className='p-3 py-2 fw-normal border-start text-break border-end'>
                                                            {
                                                                data[subIndex].notes_detail.data.dynamic_fields[item.label]?.value ?
                                                                `${data[subIndex].notes_detail.data.dynamic_fields[item.label]?.value} ${item.unit || data[subIndex].notes_detail.data.dynamic_fields[item.label]?.unit}`
                                                                :
                                                                "-"
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className='align-top p-3 py-2 border-end position-sticky start-0' style={{ backgroundColor: '#F8F9FA' }}>Uploaded Files: </th>
                                {
                                    data.map((item, index)=>{
                                        return(
                                            <th key={index} className='p-3 py-2 bg-white align-top  fw-normal border-start border-end'>
                                                <div className="text-break ">
                                                    {
                                                    data[index].notes_doc.length>0 ? data[index].notes_doc?.map((subItem, subIndex)=>{
                                                        return(
                                                        <Link className='d-flex w-100 text-decoration-none mb-2 align-items-center fw-semi-bold' target="_blank"  to={data[index].notes_url[subIndex]} key={subIndex}>
                                                            <span className="flex-shrink-0"><FileIcon width={13} /></span> &nbsp;
                                                            <div className='overflow-hidden flex-shrink-1 text-nowrap text-truncate'>
                                                                {subItem?.split('-').slice(1).join('-')}
                                                            </div>
                                                        </Link>
                                                        )
                                                    })
                                
                                                    :
                                                    '-'
                                                    }
                                                </div>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </tfoot>
                    </table>
                </div>
            }
            {
                oldData?.map((item, index)=>{
                    return (
                        <SingleNote note={item} key={index} guestId={guestId} userProgramId={userProgramId} />
                    )
                })
            }
        </>
    )
}


function SingleNote({note, userProgramId, guestId}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const notesFilters = [...searchParams.entries()].filter(item=>item[0]==="notesType").map(item=>item[1])
    const [toggle, setToggle] = useState(notesFilters?.length ? notesFilters.includes(note.notes_type) : false);
    const [deleteModal, setDeleteModal] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [visibilityModal, setVisibilityModal] =useState(false)
    const [eye, setEye] = useState(note?.is_guest_visible)
    const permissions = JSON.parse(localStorage.getItem('UserData'))?.permissions;
    const userData = JSON.parse(localStorage.getItem('UserData'));
    const canWriteNote = (permissions?.consultation_notes?.permissions || '').includes('write')
    const canWriteDietNote = (permissions?.dietary_notes?.permissions || '').includes('write')
    const canWrite = (note.notes_type === "Dietary Notes" ? canWriteDietNote : canWriteNote) && note?.user_id === userData?.user_id
  
    const deleteNote  = (id, noteType, docs) =>{
      dispatch(deleteConsultationNotes({noteId: id, noteType, docs}))
      setDeleteModal(false)
    }
    
//     const handleEye = ()=>{
//       setEye(!eye); 
//       dispatch(updateConsultationNotesVisibleStatus({noteId: note?.note_id, isVisible: !eye}))
//       setVisibilityModal(false)
//   }
  
    return (
      
      <div className=' ms-md-5'>
        <Accordion 
            expanded={toggle}
            onChange = {(e,expanded) => {
            if(expanded){
                setToggle(true)
            }else{
                setToggle(false)
            }
        }}
        style={{border: 0}}
        >
            <AccordionSummary
              expandIcon={<img src={arrowDownIcon}/>}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{width: '100%', margin: '0 !important', borderBottom: 0}}
  
              >
            <div className='accordian-title ms-3 w-100'>
              <div className="d-flex justify-content-between align-items.center">
                <div className='note-title flex-grow-1'>
                  <div className="note-footer d-flex justify-content-between align-items-center">
                    <div className='added-by'>Added By:  <span className='d-block d-md-inline'> {note?.first_name+ ' ' + note?.last_name}</span></div>
                    <div className='text-muted'>Updated On: <span className='d-block d-md-inline'> {moment(note?.f_updation_datetime).format('MMM DD, YYYY')}</span></div>
                  </div>
                </div>
                
                  <div className="icons">
                  {
                    canWrite ?
                    <>
                      <>
                        <div className="vhr"></div>
                        <img src={editBtn} alt="edit-btn" className="" onClick={()=>navigate(EDIT_CONSULTATION_NOTES_ENDPOINT, {state: { noteId: note?.note_id, guestId, userProgramId}})}/>
                        <img src={deleteBtn} alt="del-btn" className="" onClick={()=>setDeleteModal(true)}/>
                      </>
                    </>
                    :
                    <>
                      {
                        !!note?.is_guest_visible  &&
                        <>
                          <div className="vhr"></div>
                          <div className='view-permission'>Visible to Guest</div>
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="ms-md-4 ps-1 accordian-details">
                <div className="mt-3">
                    <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                        <div className='d-flex'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Conducted By:</div>
                            <div className="text-break white-space">{`${note.first_name} ${note.last_name}` || '-'}</div>
                        </div>
                        <div className='d-flex  w-25'>
                            <div className="fw-semi-bold mr-2 flex-shrink-0 me-3" >Date:</div>
                            <div className="text-break white-space">{note.notes_detail.date? moment(note.notes_detail.date).format('DD MMM, YYYY') : '-'}</div>
                        </div>
                    </div>
                    <div className='mb-3 border p-2 rounded'>
                        {
                            OldLabTestsField?.map((item, index)=>(
                                <div className='d-flex mb-3' key={index}>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                                    <div className="text-break white-space">{note?.notes_detail.data?.[item.key]?.value ? `${note?.notes_detail?.data[item.key]?.value}` : '-'}</div>
                                </div>
                                ))
                        }
                    </div>
                </div>
                <div className='d-flex mb-3 '>
                  <div className="fw-semi-bold me-2 flex-shrink-0">Uploaded Files:</div>
                  <div className="text-break ">
                    {
                      note.notes_doc.length>0 ? note.notes_doc?.map((item, index)=>{
                        return(
                          <Link className='d-block text-decoration-none mb-2 fw-semi-bold' target="_blank"  to={note.notes_url[index]} key={index}>
                            <FileIcon/> &nbsp;
                            {item?.split('-').slice(1).join('-')}
                          </Link>
                        )
                      })
  
                      :
                      '-'
                    }
                </div>
                </div>
              </div>
            </AccordionDetails>
        </Accordion>
        
        
        
        {/* <ChangeVisibilityModal
          warnModal={visibilityModal} 
          setWarnModal={setVisibilityModal} 
          onYes={handleEye}
          hide={eye}
          /> */}
        <DeleteModal 
            deleteModal={deleteModal} 
            setDeleteModal={setDeleteModal}
            name="this note"
            onDelete = {()=>deleteNote(note?.note_id, note?.notes_type, note.notes_doc) }
        />
      </div>
    )
  }

const ViewOptions = ({note, guestId, userProgramId})=>{
    const [deleteModal, setDeleteModal] = useState(false)
    const [open, setOpen] = useState(false)
    const dropRef = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const deleteNote  = (id, noteType, docs) =>{
        dispatch(deleteConsultationNotes({noteId: id, noteType, docs}))
        setDeleteModal(false)
      }

    const outsideClickHandler = (e) => {
        if (dropRef.current && !dropRef.current.contains(e.target)) {
            setOpen(false); // Close dropdown if click is outside
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", outsideClickHandler);
        
        return () => {
            document.removeEventListener("mousedown", outsideClickHandler);
        };
    }, []);

    return (
        <div className='d-inline-block lab-test-menu'>
            <img className='ms-2 cursor-pointer' src={dotIcon} alt="" onClick={()=>setOpen(prev=> !prev)} />
            <div>
                {
                    open &&
                    <div className="menu-dropdown" ref={dropRef} id="myDropdown">
                        <button className="menu-item fw-semi-bold edit border-bottom w-100" onClick={()=>navigate(EDIT_CONSULTATION_NOTES_ENDPOINT, {state: { noteId: note?.note_id, guestId, userProgramId}})}>
                        <img src={editBtn} alt="edit-btn" className="" /> Edit
                        </button>
                        <button className="menu-item w-100 fw-semi-bold text-danger" onClick={()=>{setDeleteModal(true); setOpen(false)}}>
                            <img src={deleteBtn} alt="del-btn" className="" /> Delete
                        </button>
                    </div>
                }
            </div>
            <DeleteModal
                deleteModal={deleteModal} 
                setDeleteModal={setDeleteModal}
                name="this note"
                onDelete = {()=>deleteNote(note?.note_id, note?.notes_type, note.notes_doc) }
            />
        </div>
    )
}