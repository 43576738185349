import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_REQUEST_END, LOGIN_REQUEST_START, SET_USER_DATA, VIEW_PROFILE } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import {logOut} from '../../../../utils/helpers/logout'
import apiEndpoints from "../../../../axios/api";
import { logger } from "../../../../utils/logging";

export const viewProfileReq = async()=>{
    return axiosInstance.get(apiEndpoints.VIEW_PROFILE_API)
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);

            if (errors.response?.data.statusCode === 400 ) {
                notify('error', "Unable to get data.");
            } else if(errors.response.data.statusCode === 401){
                logOut();
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* viewProfile(){
    yield put({ type: LOGIN_REQUEST_START});
    try{
        let result = yield call(viewProfileReq)
        if(result.statusCode === 200){
            const oldData = JSON.parse(localStorage.getItem('UserData'))
            const newData =  {...result.data, access_token: oldData.access_token, permissions: oldData.permissions, user_role_id: oldData.user_role_id}

            result = {...result, data:newData}
            localStorage.setItem("UserData", JSON.stringify(newData))
            yield put({ type: SET_USER_DATA, payload: result});
        }      
        yield put({ type: SET_USER_DATA, payload: result});
        yield put({ type: LOGIN_REQUEST_END});

    }catch(error){
        yield put({ type: LOGIN_REQUEST_END});

    }
}


function* viewProfileSaga(){
    yield takeEvery(VIEW_PROFILE, viewProfile)
}

export default viewProfileSaga;