import { call, put, takeEvery } from "redux-saga/effects";
import { CREATE_PASSWORD, FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS, } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";
import { logger } from "../../../../utils/logging";
import { CREATE_PASSWORD_ENDPOINT, VERIFICATION_ENDPOINT } from "../../../../routes/route";
import { formTypes } from "../../../../const/constants";

export const forgotPasswordReq = async({data})=>{
    const BODY = {
            "otp_for": "email",
            "email_id": data.email_id,
        }

    return axiosInstance.post(apiEndpoints.FORGOT_PASSWORD_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                notify("error", "Invalid OTP");
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* forgotPassword({data}){
    try{
        let result = yield call(forgotPasswordReq, {data})
        if(result.statusCode === 200){
            
            yield put({ type: FORGOT_PASSWORD_SUCCESS, payload: {...result.data, 
                otpFor: data.otp_for,
                email: data?.email_id,
                type: data?.type
            }});
            if(!result?.data?.is_otp_verified){
                data.navigate(VERIFICATION_ENDPOINT, {state: {type: formTypes.FORGOT_PASSWORD}})
                notify('success', result.message)
            }else{
                data.navigate(CREATE_PASSWORD_ENDPOINT, {state: {type: formTypes.FORGOT_PASSWORD}})
            }
    }
    }catch(error){
        logger.log(error)
        yield put({ type: FORGOT_PASSWORD_FAILURE, message: error.message });
    }
}


function* forgotPasswordSaga(){
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export default forgotPasswordSaga;