import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import './StatusReport.scss'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import * as routes from  '../../routes/route'
import SearchBar from '../../components/SearchBar/SearchBar';
import StatusReportTable from './StatusReportTable/StatusReportTable';
import { activityTypeOptions, guestStatusOptions, statusOptions, statusReportIconsOptions } from '../../const/dropdownOptions';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import RangeDatePicker from '../../components/RangeDatePicker/RangeDatePicker';
import { getStatusReportFilterList, getWellnessStatusReport } from '../../redux/actions/StatusReportActions/StatusReportAction';
import moment from 'moment';
import { validateDate } from '../../utils/helpers/universalFunctions';
import { getAnandaProgramsList } from '../../redux/actions/ProgramActions/ProgramAction';
import { getNotesTypeList } from '../../redux/actions/GuestDataActions/ConsultationNotesActions';

const columns = [
    { id: 'name', label: 'Guest Name', sortingKey: "NAME", sort: true, width: '16%' },
    { id: 'program', label: 'Programme', sortingKey: "PROGRAMME", sort: true,width: '28%' },
    { id: 'checkin_datetime', label: 'Check-In', sortingKey: "CHECK-IN", sort: true,width: '12%' },
    { id: 'checkout_datetime', label: 'Check-Out', sortingKey: "CHECK-OUT", sort: true,width: '14%' },
    { id: 'roomNo', label: 'Room No', sortingKey: "ROOM", sort: true,width: '12%' },
    { id: 'doctor', label: 'Lead Doctor', sortingKey: "DOCTOR", sort: true,width: '20%' },

  ];



  function StatusReport() {
    const {loading, statusReport, loadingFilterList, filterList} = useSelector(state=> state.statusReportReducer)
    const loadingProgramList = useSelector(state=> state.programReducer.loading)
    const programList = useSelector(state=> state.programReducer.programList)
    const {loadingNotesType, notesType} = useSelector(state=> state.consultationNotesReducer)
    const [searchParams, setSearchParams] = useSearchParams()
    const [filter, setFilter] = useState({
      searchText: searchParams.get("search") || '',
      programType: programList && !searchParams.get("programType") ? programList.map(item=> item.id)  : [...([...searchParams.entries()].filter(item=>item[0]==="programType").map(item=>parseInt(item[1])))],
      category: filterList && !searchParams.get("category") ? filterList.map(item=> item.value)  : ([...([...searchParams.entries()].filter(item=>item[0]==="category").map(item=>item[1]))] || []),
      notesType: notesType && !searchParams.get("notesType") ? notesType.map(item=> item.title)  : [...([...searchParams.entries()].filter(item=>item[0]==="notesType").map(item=>item[1]))],
      status: searchParams.get("status") || '',
      guestStatus: searchParams.get("guestStatus") || '',
      fromDate: validateDate(searchParams.get("startDate")) ? new Date(searchParams.get("startDate")) : '',
      toDate: validateDate(searchParams.get("endDate")) ? new Date(searchParams.get("endDate")) : '',
      orderBy: searchParams.get("orderBy") || columns[0].sortingKey,
      order: searchParams.get("order") || 'asc'
    })

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const getTableData = ()=>{
      let sendData={
          searchText: searchParams.get("search") || '',
          category: searchParams.get("category") ? [...([...searchParams.entries()].filter(item=>item[0]==="category").map(item=>item[1]))] : [],
          status: searchParams.get("status") || '',
          guestStatus: searchParams.get("guestStatus") || '',
          startDate: validateDate(searchParams.get("startDate")) ? searchParams.get("startDate") : '',
          endDate: validateDate(searchParams.get("endDate")) ? searchParams.get("endDate") : '',
          page: searchParams.get("page") ? parseInt(searchParams.get("page"))+1 : 1,
          pageLimit: searchParams.get("pageLimit") || 10,
          programType:  searchParams.get("programType") ? [...([...searchParams.entries()].filter(item=>item[0]==="programType").map(item=>parseInt(item[1])))] : [],
          notesType: searchParams.get("notesType") ? [...([...searchParams.entries()].filter(item=>item[0]==="notesType").map(item=>item[1]))] : [],
          orderBy:  searchParams.get("orderBy") || columns[0].sortingKey,
          order:  searchParams.get("order") || 'asc',
      }
      dispatch(getWellnessStatusReport(sendData))
    }

    const handleSingleValueFilter = (key, value)=>{
      if(value !== searchParams.get(key)){
        searchParams.delete('page')
        searchParams.delete(key)

        let newPair= value ? [[key, value?.trim()]] : [];
        navigate({
          pathname: `${routes.STATUS_REPORT_ENDPOINT}`,
          search: `?${createSearchParams([...searchParams.entries(), ...newPair])}`,
        }, {replace: true})
      }
    }

    const handleMultipleValueFilter = (key, values)=>{
      searchParams.delete("page")
      searchParams.delete(key)
      let newQueryData =[]

      if(key==='category'){
        newQueryData = (values.length === filterList?.length) ? [] : values?.map(item=>([key, item.value]))
      }else if(key==='programType'){
        newQueryData = (values.length === programList?.length) ? [] : values?.map(item=>([key, item.value]))
      }else if(key==='notesType'){
        newQueryData = (values.length === notesType?.filter(item=>item.visible_in_status_report)?.length) ? [] : values?.map(item=>([key, item.value]))
      }

      // if(!(values.length === filterList?.length) && values.length)
      //   newQueryData = [...newQueryData, ["status", "pending"]]
      // else
      //   searchParams.delete("status")

      navigate({
        pathname: `${routes.STATUS_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ...newQueryData])}`,
      }, {replace: true})
    }

    const onClear = (key)=>{
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete(key)
      navigate({
        pathname: routes.STATUS_REPORT_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      }, {replace: true})
    }

    useEffect(()=>{
        getTableData()
    },[searchParams])

    useEffect(()=>{
      if(!filterList)
        dispatch(getStatusReportFilterList())
      if(!programList)
        dispatch(getAnandaProgramsList())
      if(!notesType)
        dispatch(getNotesTypeList())
    }, [])
    
    useEffect(()=>{
      if(filterList && !searchParams.get("category")){
        setFilter(prev=>({...prev, category: filterList.map(item=> item.value)}))
      }
    }, [filterList])

    useEffect(()=>{
      if(programList && !searchParams.get("programType")){
        setFilter(prev=>({...prev, programType: programList.map(item=> item.id)}))
      }
    }, [programList])

    useEffect(()=>{
      if(notesType && !searchParams.get("notesType")){
        setFilter(prev=>({...prev, notesType: notesType.map(item=> item.title)}))
      }
    }, [notesType])
    
  return (
    <>
      <div className="status-report-container">
          <div className="container h-100">
            <div className="d-flex justify-content-between">
                <div className="status-report-heading ms-3 mb-3">
                    Status Report
                </div>
                <SearchBar
                  width={'260px'}
                      onclick={(value)=>{handleSingleValueFilter("search", value)}}
                      value={filter.searchText} 
                      onClear={()=>{onClear("search")}}
                  />
            </div>
              <div className="pt-2 pb-5">
                  <div className="status-report-content">
                      <div className='status-report-filter p-3'>
                        <div className="heading row mb-3">
                          <div className="col-2 px-1">
                            Programme
                          </div>
                          <div className="col-2 px-1">
                            Activity Filter
                          </div>
                          <div className="col-2 px-1">
                            Data and Consultations
                          </div>
                          <div className="col-2 px-1">
                            Activity Status
                          </div>
                          <div className="col-2 px-1">
                            Guest Status
                          </div>
                          <div className="col-2 px-1">
                            Date Range
                          </div>
                        </div>
                        <div className="data-row row">
                          <div className="col-2 px-1">
                            <MultiSelect
                              options={[
                                ...(programList ? programList?.map((item)=>({
                                      label: item.title,
                                      value: item.id
                                    })) : []
                                    )
                                ]}
                                width={'100%'}
                                loading={loadingProgramList}
                                defaultValue={programList?.filter((item)=>filter.programType.includes(item.id))?.map(item=>({label: item.title, value: item.id})) || []}
                                onClickOk={(values)=>{handleMultipleValueFilter("programType", values)}}
                            />
                          </div>
                          <div className="col-2 px-1">
                            <MultiSelect
                              options={[
                                ...(filterList ? filterList?.map((item)=>({
                                      label: item.title,
                                      value: item.value
                                    })) : []
                                    )
                                ]}
                                width={'100%'}
                                loading={loadingFilterList}
                                defaultValue={filterList?.filter((item)=>filter.category.includes(item.value))?.map(item=>({label: item.title, value: item.value})) || []}
                                onClickOk={(values)=>{handleMultipleValueFilter("category", values)}}
                            />
                          </div>
                          <div className="col-2 px-1">
                            <MultiSelect
                              options={[
                                ...(notesType ? notesType?.filter(item=>item.visible_in_status_report)?.map((item)=>({
                                      label: item.title,
                                      value: item.title
                                    })) : []
                                    )
                                ]}
                                width={'100%'}
                                loading={loadingNotesType}
                                defaultValue={notesType?.filter((item)=>filter.notesType.includes(item.title) && item.visible_in_status_report)?.map(item=>({label: item.title, value: item.title})) || []}
                                onClickOk={(values)=>{handleMultipleValueFilter("notesType", values)}}
                            />
                          </div>
                          <div className="col-2 px-1">
                            <Select
                              className="name-select"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="color"
                              options={statusOptions}
                              placeholder='Activity Status'
                              onChange={(e)=>{
                                handleSingleValueFilter("status", e?.value)
                              }}
                              value={statusOptions?.filter(item=> item.value==filter.status)}
                              styles={{
                                  container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '100%',
                                  }),
                                }}
                            />
                          </div>
                          <div className="col-2 px-1">
                            <Select
                              className="name-select"
                              classNamePrefix="select"
                              isSearchable={true}
                              name="color"
                              options={guestStatusOptions}
                              placeholder='Guest Status'
                              onChange={(e)=>{handleSingleValueFilter("guestStatus", e?.value)}}
                              value={guestStatusOptions?.filter(item=> item.value==filter.guestStatus)}
                              styles={{
                                  container: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '100%',
                                  }),
                                }}
                            />
                          </div>
                          <div className="col-2 px-1">
                            <RangeDatePicker
                              onClear={()=>{onClear("startDate"); onClear("endDate")}}
                              defaultStartValue={filter.fromDate}
                              defaultEndValue={filter.toDate}
                              onStartDateChange={(value)=>{handleSingleValueFilter("startDate", moment(value).format('YYYY-MM-DD'))}}
                              onEndDateChange={(value)=>{handleSingleValueFilter("endDate", moment(value).format('YYYY-MM-DD'))}}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div>
                          <StatusReportTable
                              checkbox={false}
                              columns={columns}
                              loading={loading}
                              data ={statusReport?.list?.map((item, index)=>({
                                  name: `${item.first_name} ${item.last_name}` || '-',
                                  program: item?.program_title || '-',
                                  checkin_datetime: item.check_in ? item.check_in : '-',
                                  checkout_datetime: item.check_out ? item.check_out : '-',
                                  roomNo: item?.room_no || '-',
                                  doctor: item?.assign_lead_full_name || '-',
                                  ...item
                              })) || []}
                              pagination={statusReport?.list?.length}
                              totalCount={statusReport?.total}
                              totalPages={statusReport?.total_pages}
                              start={(statusReport?.current_page_no - 1) * (statusReport?.end-statusReport?.start) + 1}
                              end={(statusReport?.current_page_no - 1) * (statusReport?.end-statusReport?.start) + statusReport?.list?.length}
                              currentPage={statusReport?.current_page_no-1}
                              rowsPerPage={searchParams.get("pageLimit") || 10}
                              filter={filter}
                              getTableData={getTableData}
                          />
                      </div>
                      {!statusReport?.list?.length && !loading && (
                        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                          No Records
                        </div>
                      )}
                  </div>
              </div>                                  
          </div>
      </div>
    </>
  )
}

export default StatusReport