import moment from 'moment'
import { AyurvedaArrivalConsultationField } from '../NoteFormsConstants'
import targetIcon from '../../../../assets/images/target.svg'


export const ViewAyurvedaArrivalConsultation = ({content, conductedBy})=>{
    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className='border rounded overflow-hidden my-3'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    CURRENT HEALTH COMPLAINTS
                </div>
                <div className=''>
                    {
                        !!content?.data?.current_health_complaints?.health_complaint_keywords?.length
                        ?
                        <div className=''>
                            <div className="row m-0 fw-semibold">
                                <div className="border py-1 col-2">Health Category</div>
                                <div className="border py-1 col-2">Health Condition</div>
                                <div className="border py-1 col-3">Details</div>
                                <div className="border py-1 col-2">Intensity</div>
                                <div className="border py-1 col-3">Duration</div>
                            </div>
                            <div>
                            {
                                content?.data.current_health_complaints.health_complaint_keywords?.map((item, index)=>{
                                    return (
                                        <div className="row m-0" key={index}>
                                            <div className="border py-1 border-bottom-0 col-2">
                                                {item?.['health_category'] || '-'}
                                            </div>
                                            <div className="border py-1 border-bottom-0 col-2">
                                                {item?.['health_condition'] || '-'}
                                            </div>
                                            <div className="border py-1 border-bottom-0 col-3">
                                                {item?.['details'] || '-'}
                                            </div>
                                            <div className="border py-1 border-bottom-0 col-2">
                                                {item?.['intensity'] || '-'}
                                            </div>
                                            <div className="border py-1 border-bottom-0 col-3">
                                                {item?.['duration'] || '-'}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                        :
                        <div className='m-3 text-center'>No Health Complaints added</div>
                    }
                </div>                
            </div>
            <div className='border rounded overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    CURRENT DIETARY PATTERN
                </div>
                <div className='p-3'>
                    <div>
                        <div className='fw-semibold mb-3 text-secondary'>INTAKE: </div>
                        <div>
                        {
                            AyurvedaArrivalConsultationField?.currentDietaryPattern?.intake?.map((item, index)=>(
                                <div className='d-flex mb-2' key={index}>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                                    <div className="text-break white-space">{content.data?.current_dietary_pattern?.intake?.[item.key] || '-'}</div>
                                </div>
                                ))
                        }
                        </div>
                    </div>
                    <hr />
                    <div className='mt-3'>
                        <div className='fw-semibold mb-3 text-secondary'>ADDITIONAL INFORMATION: </div>
                        <div>
                        {
                            AyurvedaArrivalConsultationField?.currentDietaryPattern?.additionalInfo?.map((item, index)=>(
                                <div className='d-flex mb-2' key={index}>
                                    <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                                    <div className="text-break white-space">{content.data?.current_dietary_pattern?.additional_info?.[item.key] || '-'}</div>
                                </div>
                                ))
                        }
                        </div>
                    </div>
                </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    AYURVEDIC HEALTH ASSESSMENT
                </div>
                <div className='m-3'>
                    {
                        AyurvedaArrivalConsultationField?.healthAssessment?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content.data?.ayurvedic_health_assessment?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    SYSTEMIC  ASSESSMENT
                </div>
                <div className='m-3'>
                    <div>
                        <div className='fw-semibold mb-3 text-secondary'>RESPIRATORY SYSTEM </div>
                        <div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Lungs Field:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.respiratory_system?.lung_field || '-'}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Auscultation:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.respiratory_system?.auscultation || '-'}</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-3'>CARDIOVASCULAR SYSTEM </div>
                        <div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Pulse Rate:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.value || '-'} {content.data?.systemic_assessment?.cardiovascular_system?.pulse_rate?.unit}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Blood pressure:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.value || '-'} {content.data?.systemic_assessment?.cardiovascular_system?.blood_pressure?.unit}</div>
                            </div>
                            <div className='d-flex mb-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>SpO<sub>2</sub>:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.spo2?.value || '-'} {content.data?.systemic_assessment?.cardiovascular_system?.spo2?.unit}</div>
                            </div>
                            <div className='d-flex my-2'>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Remarks:</div>
                                <div className="text-break white-space">{content.data?.systemic_assessment?.cardiovascular_system?.remarks || '-'}</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-3'>MUSCULOSKELETAL SYSTEM </div>
                        <div className="text-break white-space">{content.data?.systemic_assessment?.musculoskeletal_system || '-'}</div>
                    </div>
                    <hr />
                    <div>
                        <div className='fw-semibold text-secondary my-3'>SKIN </div>
                        <div className="text-break white-space">{content.data?.systemic_assessment?.skin || '-'}</div>
                    </div>
                </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    ASHTAVIDHA PARIKSHA
                </div>
                <div className='m-3'>
                    {
                        AyurvedaArrivalConsultationField?.ashthavidhaPariksha?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '260px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content.data?.ashthavidha_pariksha?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                LAB REPORTS/ OTHER OBSERVATIONS
                </div>
                <div className='m-3'>
                    <div className="text-break white-space">{content.data?.lab_reports || '-'}</div>
                </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                SUMMARY ASSESSMENT AND TREATMENT PLAN
                </div>
                <div className='m-3'>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Prakriti:</div>
                        <div className="text-break white-space">{content.data?.summary_assessment_treatment_plan?.dosha_prakriti || '-'}</div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Vikruti (to be balanced):</div>
                        <div className="text-break white-space">{content.data?.summary_assessment_treatment_plan?.dosha_vikruiti || '-'}</div>
                    </div>
                    <div>
                        <div className='fw-semibold mt-5 mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>
                        
                        {
                            content?.data?.summary_assessment_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className='border mb-3 ' key={index}>
                                        <div className='fw-semibold text-secondary p-2 bg-light text-uppercase'> 
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}

                                        </div>
                                        <table className='p-2 w-100'>
                                            <tbody>
                                            {
                                                AyurvedaArrivalConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.summary_assessment_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
