import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import EmailLogin from './screens/Login/EmailLogin';
import PhoneNoLogin from './screens/Login/PhoneNoLogin';
import UpdateMobileNo from './screens/updateMobileNo/UpdateMobileNo';
import Verification from './screens/Verification/Verification';
import NotFound from './screens/NotFound/NotFound';
import GuestList from './screens/GuestList/GuestList';
import ProtectedRoute from './routes/ProtectedRoute';
import Profile from './screens/Profile/Profile';
import EditProfile from './screens/EditProfile/EditProfile';
import PrelimAssessmentDetail from './screens/PrelimAssessment/PrelimAssessmentDetail/PrelimAssessmentDetail';
import DashboardLayout from './components/Dashboard Layout/DashboardLayout';
import AddPrelimAssessment from './screens/PrelimAssessment/AddPrelimAssessment/AddPrelimAssessment';
import EditPrelimAssessment from './screens/PrelimAssessment/EditPrelimAssessment/EditPrelimAssessment';
import ViewWellbeingReport from './screens/WellbeingReport/ViewWellbeingReport/ViewWellbeingReport';
import ViewFullWellbeingReport from './screens/WellbeingReport/ViewFullWellbeingReport/ViewFullWellbeingReport';
import { useDispatch } from 'react-redux';
import './App.scss';
import * as routes from './routes/route'
import ScrollToTop from './components/ScrollToTop';
import { viewProfile } from './redux/actions/AuthActions/ViewProfileAction';
import getPermissions from './utils/helpers/getPermissions'
import EditWellbeingReport from './screens/WellbeingReport/EditWellbeingReport/EditWellbeingReport';
import ReopenForGuest from './screens/WellbeingReport/ReopenForGuest/ReopenForGuest';
import SearchGuest from './screens/SearchGuest/SearchGuest';
import SearchResult from './screens/SearchResult/SearchResult';
import PreparatoryDiet from './screens/PreparatoryDiet/PreparatoryDiet';
import EditPreparatoryDiet from './screens/PreparatoryDiet/EditPreparatoryDiet/EditPreparatoryDiet';
import Notes from './screens/Notes/Notes'
import AddNotes from './screens/Notes/AddNotes/AddNotes'
import EditNotes from './screens/Notes/EditNotes/EditNotes'
import Sessions from './screens/Sessions/Sessions'
import AddEditSessions from './screens/Sessions/AddEditSessions/AddEditSessions'
import Query from './screens/Query/Query';
import Chat from './screens/Query/Chat/Chat';
import GroupClassAndActivity from './screens/GroupClassAndActivity/GroupClassAndActivity';
import InterestedGuestList from './screens/InterestedGuestList/InterestedGuestList'
import PrintViewWellbeingReport from './screens/WellbeingReport/ViewWellbeingReport/PrintViewWellbeingReport';
import StatusReport from './screens/StatusReport/StatusReport';
import Home from './screens/Home/Home';
import Testimonial from './screens/Testimonial/Testimonial';
import ProgrammeFeedback from './screens/ProgrammeFeedback/ProgrammeFeedback';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import CreatePassword from './screens/CreatePassword/CreatePassword';
import EnterPassword from './screens/EnterPassword/EnterPassword';
import Help from './screens/Help/Help';
import ViewUserManual from './screens/Help/ViewUserManual';

function App() {
  const [ userAuthenticate, setUserAuthenticate] = useState(false);
  const dispatch = useDispatch();
    const authenticateUser = () => {
        const isAuthenticated = localStorage.getItem('IsLogin')
        if (isAuthenticated) {
            return true
        } else {
            return false
        }
    }
    
    useEffect(()=>{
      const auth = authenticateUser();
      if(auth){
        dispatch(viewProfile())
      }
      // debugger
      setUserAuthenticate(auth);
      //eslint-disable-next-line
    },[])

  return (
    <BrowserRouter>
      <ScrollToTop/>

      <Routes>
        {/* <Route path={routes.LOGIN_WITH_MOBILE_ENDPOINT} element={userAuthenticate ? <Navigate to="/" /> : <PhoneNoLogin />} /> */}
        <Route path={routes.LOGIN_WITH_EMAIL_ENDPOINT} element={userAuthenticate ? <Navigate to="/" /> : <EmailLogin />} />
        <Route path={routes.UPDATE_MOBILE_NUMBER_ENDPOINT}element={userAuthenticate ? <Navigate to="/" /> : <UpdateMobileNo />} />
        <Route path={routes.FORGOT_PASSWORD_ENDPOINT}element={userAuthenticate ? <Navigate to="/" /> : <ForgotPassword />} />
        <Route path={routes.CREATE_PASSWORD_ENDPOINT}element={userAuthenticate ? <Navigate to="/" /> : <CreatePassword />} />
        <Route path={routes.ENTER_PASSWORD_ENDPOINT}element={userAuthenticate ? <Navigate to="/" /> : <EnterPassword />} />
        <Route path={routes.VERIFICATION_ENDPOINT} element={<Verification/>} />
        <Route path={routes.NOT_FOUND_ENDPOINT} element={<NotFound/>}/>

        <Route path="/" element={<ProtectedRoute><DashboardLayout/></ProtectedRoute>}>
          <Route index element={<Home/>}/>

          <Route path={routes.VIEW_PROFILE_ENDPOINT} element={<Profile/>}/>
          <Route path={routes.EDIT_PROFILE_ENDPOINT} element={<EditProfile/>}/>
          
          {getPermissions("guest_listing")!=='hide' && <Route path={routes.GUEST_LIST_ENDPOINT} element={<GuestList/>} />}

          {getPermissions("guest_search")!=='hide' && <Route path={routes.SEARCH_ENDPOINT} element={<SearchGuest/>} />}
          {getPermissions("guest_search")!=='hide' && <Route path={routes.SEARCH_RESULT_ENDPOINT} element={<SearchResult/>} />}

          {getPermissions("wellbeing_queries")!=='hide' && <Route path={routes.QUERY_ENDPOINT} element={<Query/>}/>}
          {getPermissions("wellbeing_queries")!=='hide' && <Route path={`${routes.QUERY_ENDPOINT}/:guestId`} element={<Query/>}/>}
          {getPermissions("wellbeing_queries")!=='hide' && <Route path={routes.CHAT_ENDPOINT} element={<Chat/>}/>}

          {getPermissions("grop_activity_registration")!=='hide' && <Route path={routes.GROUP_CLASSESS_AND_ACTIVITY_ENDPOINT} element={<GroupClassAndActivity/> }/>}
          {getPermissions("grop_activity_registration")!=='hide' && <Route path={routes.VIEW_GUEST_LIST_ENDPOINT} element={<InterestedGuestList/> }/>}

          {getPermissions("prelim_assessment") !== "hide" && <Route path={routes.VIEW_PRELIM_ASSESMENT_ENDPOINT} element={<PrelimAssessmentDetail/>}/>}          
          {getPermissions("prelim_assessment") !== "hide" && <Route path={routes.ADD_PRELIM_ASSESMENT_ENDPOINT} element={<AddPrelimAssessment/>}/>}
          {getPermissions("prelim_assessment") !== "hide" && <Route path={routes.EDIT_PRELIM_ASSESMENT_ENDPOINT} element={<EditPrelimAssessment/>}/>}

          {getPermissions("wellbeing_report") !== "hide" && <Route path={routes.VIEW_WELLBEING_REPORT_ENDPOINT} element={<ViewWellbeingReport/>}/>}
          {getPermissions("wellbeing_report") !== "hide" && <Route path={routes.VIEW_FULL_WELLBEING_REPORT_ENDPOINT} element={<ViewFullWellbeingReport/>}/>}
          {getPermissions("wellbeing_report") !== "hide" && <Route path={routes.EDIT_WELLBEING_REPORT_ENDPOINT} element={<EditWellbeingReport/>}/>}
          {getPermissions("wellbeing_report") !== "hide" && <Route path={routes.REOPEN_REPORT_FOR_GUEST_ENDPOINT} element={<ReopenForGuest/>}/>}


          {getPermissions("consultation_notes") !== "hide" && <Route path={routes.VIEW_PREPARATORY_DIET_ENDPOINT} element={<PreparatoryDiet/>}/>}
          {getPermissions("consultation_notes") !== "hide" && <Route path={routes.EDIT_PREPARATORY_DIET_ENDPOINT} element={<EditPreparatoryDiet/>}/>}

          {getPermissions("consultation_notes") !== "hide" && <Route path={routes.VIEW_CONSULTATION_NOTES_ENDPOINT} element={<Notes />}/>}
          {getPermissions("consultation_notes") !== "hide" && <Route path={routes.ADD_CONSULTATION_NOTES_ENDPOINT} element={<AddNotes />}/>}
          {getPermissions("consultation_notes") !== "hide" && <Route path={routes.EDIT_CONSULTATION_NOTES_ENDPOINT} element={<EditNotes/>}/>}

          {getPermissions("session_module") !== "hide" && <Route path={routes.VIEW_SESSION_ENDPOINT} element={<Sessions />}/>}
          {getPermissions("session_module") !== "hide" && <Route path={routes.VIEW_SESSION_NOTES_ENDPOINT} element={<AddEditSessions action={'view'} />}/>}
          {getPermissions("session_module") !== "hide" && <Route path={routes.ADD_SESSION_NOTES_ENDPOINT} element={<AddEditSessions action={'add'} />}/>}
          {getPermissions("session_module") !== "hide" && <Route path={routes.EDIT_SESSION_NOTES_ENDPOINT} element={<AddEditSessions action={'edit'} />}/>}
          <Route path={routes.STATUS_REPORT_ENDPOINT} element={<StatusReport/>}/>
          <Route path={routes.VIEW_TESTIMONIAL_ENDPOINT} element={<Testimonial/>}/>
          <Route path={routes.VIEW_PROGRAMME_FEEDBACK_ENDPOINT} element={<ProgrammeFeedback/>}/>
          <Route path={routes.HELP_ENDPOINT} element={<Help/>}/>
          <Route path={`${routes.VIEW_HELP_ENDPOINT}/:id`} element={<ViewUserManual/>}/>
        </Route>
          {getPermissions("wellbeing_report") !== "hide" && <Route path={`${routes.PRINT_WELLBEING_REPORT_ENDPOINT}/:guestId/:userProgramId`} element={<ProtectedRoute><PrintViewWellbeingReport/></ProtectedRoute>}/>}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer/>
    </BrowserRouter>
  );
}

export default App;
