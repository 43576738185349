import { call, put, takeEvery } from "redux-saga/effects";
import { SET_VERIFICATION_DATA, UPDATE_MOBILE } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";
import { logger } from "../../../../utils/logging";

export const updateReq = async({data})=>{
    const BODY = { email_id: data.email_id, phone_no: data.phone_no}
        
    return axiosInstance.put(apiEndpoints.UPDATE_MOBILE_NUMBER_OTP, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);

            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 401) {
                logger.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* update({data}){
    try{
        let result = yield call(updateReq, {data})
        if(result.statusCode === 200){
            notify('success', result.message)
            yield put({ type: SET_VERIFICATION_DATA, payload: {
                otpFor: "update",
                phone: data?.phone_no,
                email: data?.email_id
            } });   
        }
    }catch(error){
    }
}


function* updateSaga(){
    yield takeEvery(UPDATE_MOBILE, update)
}

export default updateSaga;