import { useEffect } from 'react'
import TextArea from '../../../../../components/TextArea/TextArea'
import { TextField } from '../../../../../components/TextField/TextField'
import { DietrayNoteField, DietrayNoteJSON, dietType } from '../../NoteFormsConstants'
import './AddEditDietaryNotes.scss'
import DatePicker from '../../../../../components/DatePicker/DatePicker'
import moment from 'moment'
import Select from 'react-select'
import {ReactComponent as RemoveBtn } from '../../../../../assets/images/delete.svg'
import { ReactComponent as Add } from '../../../../../assets/images/prelimAdd.svg'

export const AddEditDietaryNotes = ({content, setContent, uploadComponent, setActionOnSubmit})=>{
    
    const setDietNote = (key, value)=>{
        setContent(prev=>{
          let data = { ...prev}
          data[key] = value;
          return data;
        })
      }

    const removeDietDay = (index)=>{
        setContent(prev=>{
            let updatedData = {...prev};
            updatedData.detox_diet_days.splice(index, 1);
            return updatedData
        })
    }
    
    const handleAddMoreDietDay=()=>{
        setContent(prev=>{
            let updatedData = { ...prev}
            updatedData.detox_diet_days = [...(Array.isArray(updatedData.detox_diet_days) ? updatedData.detox_diet_days : []), DietrayNoteJSON.detox_diet_days[0]]
            return updatedData;
        })
    }

    const setKey = (keys, value)=>{
      setContent(prev=>{
        let updatedData = { ...prev}
        keys.reduce((acc, key, index) => {
            if (index === keys.length - 1) {
              acc[key] = value; 
            } else {
                if(Array.isArray(acc[key])){
                    acc[key] = [...acc[key] ]
                }else{
                    acc[key] = { ...acc[key] };
                }
            }
            return acc[key];
          }, updatedData);
        return updatedData;
      })
    }

    useEffect(()=>{
      if(content.liquid_diet_days){
        setContent(prev=>{
          let data={...prev};
          data.detox_diet_days.push({
            "detox_type": "LQD",
            "diet_date": "",
            "diet_details": content.liquid_diet_days
          })

          delete data.liquid_diet_days;
          return data;
        })
      }
    }, [content])

    useEffect(()=>{
      setActionOnSubmit(prev=>{
        return ()=>{
          setContent(pre=>{
            let data= {...pre};
            data.detox_diet_days.sort((a, b) => {
              if (a.detox_type === "LQD" && b.detox_type !== "LQD") return 1;
              if (a.detox_type !== "LQD" && b.detox_type === "LQD") return -1;
              return 0;
            });
            return data;
          })
        }
      })
    }, [])

    return (
        <div className="dietary-notes-form">
          <div className='text-danger border border-danger rounded py-2 fw-semi-bold text-center my-2'>
            THIS ENTIRE FORM WILL BE AUTOMATICALLY VISIBLE TO GUEST ON SUBMISSION
          </div>
              {/* {label: "Detox Diet Days", key: 'detox_diet_days', type: 'input'}, */}

          <div className="data-row my-3">
            <div className="title mt-4">Detox Diet Days</div>
            <div className='data-input'>
               <div className="row border-top border-bottom py-2 mt-2 fw-semi-bold text-muted m-0">
                  <div className="col-4 px-0">Detox Type</div>
                  <div className="col-4 px-0">Diet Date</div>
                  <div className="col-4 px-0">Diet Details</div>
               </div>

               {
                (Array.isArray(content?.detox_diet_days) ? content?.detox_diet_days : [])?.map((item, index)=>{
                  return (
                    <div className="row m-0" key={index}>
                      <div className="col-4 ps-0 pt-2">
                        <Select
                          className="name-select"
                          classNamePrefix="select"
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={dietType}
                          placeholder='Select Detox Type'
                          value={
                            content.detox_diet_days?.[index]?.detox_type ?
                            {label: content.detox_diet_days?.[index]?.detox_type, value: content.detox_diet_days?.[index]?.detox_type}
                            :
                            ''
                          }
                          onChange={e =>setKey(["detox_diet_days", index, "detox_type"], e.value)}
                        />
                      </div>
                      <div className="col-4 ps-0 pt-2">
                        <DatePicker
                          value={content.detox_diet_days?.[index]?.diet_date ? new Date(content.detox_diet_days?.[index]?.diet_date) : ''}
                          onChange={date=>setKey( ["detox_diet_days", index, "diet_date"], moment(date).format('YYYY-MM-DD'))}
                          />
                      </div>
                      <div className="col-4 ps-0 d-flex">
                          <div className="flex-grow-1">
                            <TextArea
                              row={2}
                              placeholder={"Enter "}
                              onChange={value =>setKey(["detox_diet_days", index, "diet_details"], value)}
                              value={content?.detox_diet_days?.[index]?.diet_details || ''}/>
                          </div>
                          <div className='remove-btn mt-3 ms-2' onClick={()=>removeDietDay(index)}>
                              <RemoveBtn/>
                          </div>
                      </div>
                    </div>
                  )
                })
               }

              <div>
                <div className='cursor-pointer mt-4 mx-auto' style={{width: 'fit-content'}} onClick={()=>handleAddMoreDietDay()}>
                  <Add/>
                </div>
              </div>

            </div>
          </div>
        
        {
            DietrayNoteField?.map((item, index)=>(
              <div className="data-row" key={index}>
                <div className="title">{item.label}</div>
                <div className='data-input'>
                    {
                    item.type==='input'?
                    <div className='input-container'>
                      <TextField 
                        type='text'
                        placeholder='Enter Keywords' 
                        value={content?.[item.key] || ''} 
                        onChange={e=>setDietNote(item.key, e.target.value)}
                      />
                    </div>
                    :
                    <TextArea
                      placeholder={"Enter Keywords"}
                      onChange={value =>setDietNote(item.key, value)}
                      value={content?.[item.key] || ''}/>
                    }
                </div>
              </div>
            ))
        }
        <div className='w-50'>
        {uploadComponent}
        </div>
        </div>
    )
}