import moment from 'moment'
import { AyurvedaDepartureConsultationField } from '../NoteFormsConstants'
import { useSelector } from 'react-redux'
import targetIcon from '../../../../assets/images/target.svg'


export const ViewAyurvedaDepartureConsultation = ({content, guestName, conductedBy})=>{
    const consultationNotesData = useSelector(state => state.consultationNotesReducer)

    return (
        <div className="my-3 ">
            <div className="rounded border d-flex justify-content-between bg-light p-2 mb-2">
                <div className='d-flex'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Conducted By:</div>
                    <div className="text-break white-space">{conductedBy || '-'}</div>
                </div>
                <div className='d-flex  w-25'>
                    <div className="fw-semi-bold mr-2 flex-shrink-0  me-3" >Date:</div>
                    <div className="text-break white-space">{content.date? moment(content.date).format('DD MMM, YYYY') : '-'}</div>
                </div>
            </div>
            <div className=" w-100 rounded my-4">
                <div className="fw-semi-bold">
                    Dear {guestName},
                </div>
                Congratulations on successfully completing your wellness programme. To sustain the results of your programme, it is essential to adhere to the recommendations outlined in the debrief consultation document for a minimum duration of 3 months. For any clarifications during this period, consult Ananda’s Ayurvedic Physician during post-stay online follow-ups.  
            </div>
            <div className='border rounded overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                PROGRAMME & TREATMENT PLAN
                </div>
                <div className='p-3'>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Prakriti:</div>
                        <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_prakriti || '-'}</div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '230px'}}>Dosha Vikruti (to be balanced):</div>
                        <div className="text-break white-space">{content.data?.programme_and_treatment_plan?.dosha_vikruiti || '-'}</div>
                    </div>
                    <div className='fw-semibold mt-5 mb-3 text-uppercase  text-secondary'>Primary objectives for improvement: <span className="fw-normal text-dark">{content?.data?.summary_assessment_treatment_plan?.objectives?.length ? '' : <>&nbsp; -</>} </span> </div>

                    {
                        content?.data?.programme_and_treatment_plan?.objectives?.map((item, index)=>{
                                return (
                                    <div className=' mt-4 ' key={index}>
                                        <div className='border bg-light fw-semibold text-secondary p-2 text-uppercase'>                                             
                                            <img src={targetIcon} className='me-2' width={'20px'} alt="" />{(index+1)?.toString()?.padStart(2, 0)}
                                        </div>
                                        <table className='p-2 w-100'>
                                            <tbody>
                                            {
                                                AyurvedaDepartureConsultationField?.objectives?.map((item, subIndex)=>(
                                                    <tr className='d-flex' key={subIndex}>
                                                        <td className="fw-semi-bold border p-2 mr-2 flex-shrink-0" style={{width: '230px'}}>{item?.label}</td>
                                                        <td className="text-break border flex-grow-1 p-2 white-space">{content.data?.programme_and_treatment_plan?.objectives?.[index]?.[item.key] || '-'}</td>
                                                    </tr>
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })
                        }
                        
                </div>
            </div>
            <div className='border rounded overflow-hidden my-3'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    CURRENT HEALTH COMPLAINTS
                </div>
                    <div className=''>
                        {
                            !!content?.data?.current_health_complaints?.health_complaint_keywords?.length
                            ?
                            <div className=''>
                                <div className="row m-0 fw-semibold ">
                                    <div className="border py-1 col-2">Health Category</div>
                                    <div className="border py-1 col-2">Health Condition</div>
                                    <div className="border py-1 col-3">Details</div>
                                    <div className="border py-1 col-2">Intensity</div>
                                    <div className="border py-1 col-3">Duration</div>
                                </div>
                                <div>
                                {
                                    content?.data?.current_health_complaints?.health_complaint_keywords?.map((item, index)=>{
                                        return (
                                            <div className="row m-0" key={index}>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['health_category'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['health_condition'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-3">
                                                    {item?.['details'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-2">
                                                    {item?.['intensity'] || '-'}
                                                </div>
                                                <div className="border py-1 border-bottom-0 col-3">
                                                    {item?.['duration'] || '-'}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </div>
                            :
                            <div className='m-3 text-center'>No Health Complaints added</div>
                        }
                    </div>                
                </div>
            <div className='border rounded my-4 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                AYURVEDIC HEALTH ASSESSMENT
                </div>
                <div className="row m-0 fw-semibold" >
                    <div className="p-2 border col-3">Parameter</div>
                    <div className='p-2 border col-4'>
                        On Arrival
                    </div>
                    <div className='p-2 border col-5'>
                        On Departure
                    </div>
                </div>
                    {
                        AyurvedaDepartureConsultationField?.healthAssessment?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-4'>
                                        {content?.data?.health_assessment?.[item.key]?.arrival_reading || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-5'>
                                        {content?.data?.health_assessment?.[item.key]?.value || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='border rounded my-4 overflow-hidden'>
                    <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    SYSTEMIC  ASSESSMENT
                    </div>
                    <div>
                        <div className="row m-0">
                            <div className="sub-heading text-brown bg-light fw-semi-bold text-uppercase py-2">
                                Respiratory System:
                            </div>
                        </div>
                        <div className="row m-0 fw-semibold" >
                            <div className="p-2 border col-3"></div>
                            <div className='p-2 border col-4'>
                                On Arrival
                            </div>
                            <div className='p-2 border col-5'>
                                On Departure
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">Lung Field:</div>
                            <div className='border border-bottom-0 p-2 col-4'>
                                {content?.data?.systemic_assessment?.respiratory_system?.["lung_field"]?.arrival_reading || '-'}
                            </div>
                            <div className='border border-bottom-0 p-2 col-5'>
                                {content?.data?.systemic_assessment?.respiratory_system?.["lung_field"]?.value || '-'}
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border p-2 col-3 fw-semi-bold text-secondary ">Auscultation:</div>
                            <div className='border p-2 col-4'>
                                {content?.data?.systemic_assessment?.respiratory_system?.["auscultation"]?.arrival_reading || '-'}
                            </div>
                            <div className='border p-2 col-5'>
                                {content?.data?.systemic_assessment?.respiratory_system?.["auscultation"]?.value || '-'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row m-0">
                            <div className="sub-heading text-brown bg-light fw-semi-bold text-uppercase py-2">
                            Cardiovascular System:
                            </div>
                        </div>
                        <div className="row m-0 fw-semibold" >
                            <div className="p-2 border col-3"></div>
                            <div className='p-2 border col-4'>
                                On Arrival
                            </div>
                            <div className='p-2 border col-5'>
                                On Departure
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">Pluse Rate:</div>
                            <div className='border border-bottom-0 p-2 col-4'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["pulse_rate"]?.arrival_reading || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["pulse_rate"]?.unit}

                            </div>
                            <div className='border border-bottom-0 p-2 col-5'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["pulse_rate"]?.value || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["pulse_rate"]?.unit}
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">Blood Pressure:</div>
                            <div className='border border-bottom-0 p-2 col-4'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["blood_pressure"]?.arrival_reading || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["blood_pressure"]?.unit}
                            </div>
                            <div className='border border-bottom-0 p-2 col-5'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["blood_pressure"]?.value || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["blood_pressure"]?.unit}
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">SpO<sub>2</sub>:</div>
                            <div className='border border-bottom-0 p-2 col-4'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["spo2"]?.arrival_reading || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["spo2"]?.unit}
                            </div>
                            <div className='border border-bottom-0 p-2 col-5'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["spo2"]?.value || '-'}
                                &nbsp;
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["spo2"]?.unit}
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className=" border p-2 col-3 fw-semi-bold text-secondary ">Remarks:</div>
                            <div className='border p-2 col-4'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["remarks"]?.arrival_reading || '-'}
                            </div>
                            <div className='border p-2 col-5'>
                                {content?.data?.systemic_assessment?.cardiovascular_system?.["remarks"]?.value || '-'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row m-0">
                            <div className="sub-heading text-brown bg-light fw-semi-bold text-uppercase py-2">
                            Musculoskeletal system:
                            </div>
                        </div>
                        <div className="row m-0 fw-semibold" >
                            <div className='p-2 border col-6'>
                                On Arrival
                            </div>
                            <div className='p-2 border col-6'>
                                On Departure
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className='border p-2 col-6'>
                                {content?.data?.systemic_assessment?.musculoskeletal_system?.arrival_reading || '-'}
                            </div>
                            <div className='border p-2 col-6'>
                                {content?.data?.systemic_assessment?.musculoskeletal_system?.value || '-'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row m-0">
                            <div className="sub-heading text-brown bg-light fw-semi-bold text-uppercase py-2">
                            Skin:
                            </div>
                        </div>
                        <div className="row m-0 fw-semibold" >
                            <div className='p-2 border col-6'>
                                On Arrival
                            </div>
                            <div className='p-2 border col-6'>
                                On Departure
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className='border border-bottom-0 p-2 col-6'>
                                {content?.data?.systemic_assessment?.skin?.arrival_reading || '-'}
                            </div>
                            <div className='border border-bottom-0 p-2 col-6'>
                                {content?.data?.systemic_assessment?.skin?.value || '-'}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='border rounded my-4 overflow-hidden'>
                    <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                    ASHTAVIDHA PARIKSHA

                    </div>
                    <div className="row m-0 fw-semibold" >
                        <div className="p-2 border col-3"></div>
                        <div className='p-2 border col-4'>
                            On Arrival
                        </div>
                        <div className='p-2 border col-5'>
                            On Departure
                        </div>
                    </div>
                    {
                        AyurvedaDepartureConsultationField?.ashthavidhaPariksha?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary ">{item.label}</div>
                                    <div className='border border-bottom-0 p-2 col-4'>
                                        {content?.data?.ashthavidha_pariksha?.[item.key]?.arrival_reading || '-'}
                                    </div>
                                    <div className='border border-bottom-0 p-2 col-5'>
                                        {content?.data?.ashthavidha_pariksha?.[item.key]?.value || '-'}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='border rounded my-4 overflow-hidden'>
                    <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                        LAB REPORTS/ OTHER OBSERVATIONS
                    </div>
                    <div className="row m-0 mt-3">
                        <div className="text-muted fw-semi-bold col-2">
                            On Arrival
                        </div>
                        <div className='col-10'>
                            {content?.data?.lab_reports?.arrival_reading || '-'}
                        </div>
                    </div>
                    <div className="row my-3 m-0">
                        <div className="text-muted fw-semi-bold col-2">
                            On Departure
                        </div>
                        <div className='col-10'>
                            {content?.data?.lab_reports?.value || '-'}
                        </div>
                    </div>
                </div>

            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                RECOMMENDATIONS AND FOLLOW UPS
                </div>
                <div className=''>
                    <div className="row m-0 fw-semibold" >
                        <div className="p-2 border col-3">Daily Routine</div>
                        <div className='p-2 border col-4'>
                            Time
                        </div>
                        <div className='p-2 border col-5'>
                            Frequency
                        </div>
                    </div>
                        {
                            AyurvedaDepartureConsultationField?.recommendationsAndFollowUps?.map((item, index)=>{
                                return (
                                    <div className="row w-100 m-0" key={index}>
                                        <div className=" border border-bottom-0 p-2 col-3 fw-semi-bold text-secondary">{item.label}</div>
                                        <div className='col-4 border border-bottom-0 p-2 '>
                                            {content?.data?.recommendations_and_follow_ups?.[item.key]?.time || '-'}
                                        </div>
                                        <div className='col-5 border border-bottom-0 p-2 '>
                                            <div className="data-input">
                                                {content?.data?.recommendations_and_follow_ups?.[item.key]?.frequency || '-'}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
            </div>
            <div className='border rounded my-3 overflow-hidden'>
                <div className="bg-light text-center text-brown border-bottom py-2 fw-semibold">
                OTHER RECOMMENDATIONS
                </div>
                <div className='m-3 mb-5'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Dietary Recommendations</div>
                    {
                        AyurvedaDepartureConsultationField?.otherRecommendations?.dietaryRecommendation?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '260px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content?.data?.other_recommendations?.dietary?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
                <hr />
                <div className='m-3 mb-5'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Physical & Mental Activity</div>
                    {
                        AyurvedaDepartureConsultationField?.otherRecommendations?.physicalAndMentalActivity?.map((item, index)=>(
                            <div className='d-flex mb-2' key={index}>
                                <div className="fw-semi-bold mr-2 flex-shrink-0" style={{width: '260px'}}>{item?.label}:</div>
                                <div className="text-break white-space">{content?.data?.other_recommendations?.physical_mental?.[item.key] || '-'}</div>
                            </div>
                            ))
                    }
                </div>
                <hr />
                <div className='m-3'>
                    <div className="text-secondary text-uppercase fw-semi-bold my-3">Medicines Recommended</div>
                    <div className="text-muted fw-semibold m-0 text-center row">
                        <div className="col-4 border p-2">Medicine</div>
                        <div className="col-4 border p-2">Dosage</div>
                        <div className="col-4 border p-2">Frequency</div>
                    </div>
                    {
                        content.data?.other_recommendations?.medicine?.map((item, index)=>{
                            return (
                                <div className="row m-0" key={index}>
                                    <div className='col-4 border border-top-0 p-2'>
                                        {content?.data?.other_recommendations?.medicine?.[index]?.medicines || '-'}
                                    </div>
                                    <div className='col-4 border border-top-0 p-2'>
                                        {content?.data?.other_recommendations?.medicine?.[index]?.dosage || '-'}
                                    </div>
                                    <div className='col-4 border border-top-0 p-2 align-items-center d-flex'>
                                        <div className="flex-grow-1 me-3">
                                            {content?.data?.other_recommendations?.medicine?.[index]?.frequency || '-'}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="border rounded my-3 overflow-hidden">
                <div className="bg-light text-brown text-center py-2 fw-semibold">
                FOLLOW UP PROGRAMME
                </div>
                <div className=' '>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Consultation:</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.consultation || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Programme):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.programme || '-'}</div>
                    </div>
                    <div className='d-flex'>
                        <div className="fw-semi-bold border p-2 flex-shrink-0" style={{width: '230px'}}>Follow up Visit (Timeline):</div>
                        <div className="text-break flex-grow-1 border p-2 white-space">{content?.data?.follow_up_programme?.timeline || '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
