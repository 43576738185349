import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN, LOGIN_FAILURE, LOGIN_REQUEST_END, LOGIN_REQUEST_START, SET_LOGIN_DATA, SET_VERIFICATION_DATA } from "../../../constants";
import axiosInstance from "../../../../axios/axiosInstance";
import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../../axios/api";
import { CREATE_PASSWORD_ENDPOINT, ENTER_PASSWORD_ENDPOINT, VERIFICATION_ENDPOINT } from "../../../../routes/route";
import { logger } from "../../../../utils/logging";

export const loginReq = async({data})=>{
    const BODY = data.otp_for === "email" ?
        { wellness_web_app : 1, otp_for: data.otp_for, email_id: data.email_id} :
        { wellness_web_app : 1, otp_for: data.otp_for, phone_no: data.phone_no}
        

    return axiosInstance.post(apiEndpoints.LOGIN_API, { data: BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            logger.log("errors", errors);

            if (errors.response?.data.statusCode === 400 ) {
                notify('success', "This is not a wellness user");
            }else if (errors.response?.data.statusCode === 401) {
                logger.log(errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}

function* login({data}){
    yield put({ type: LOGIN_REQUEST_START })
    try{
        let result = yield call(loginReq, {data})
        if(result.statusCode === 200){
            yield put({ type: SET_LOGIN_DATA, payload: {...result.data, 
                otpFor: data.otp_for,
                email: data?.email_id,
                type: data?.type
            }});
            // if(!result?.data?.is_otp_verified){
            //     data.navigate(VERIFICATION_ENDPOINT, {state: {type: data?.type}})
            //     notify('success', result.message)
            // }else{
            //     if(result?.data?.is_password_set)
            //         data.navigate(ENTER_PASSWORD_ENDPOINT, {state: {type: data?.type}})
            //     else
            //         data.navigate(CREATE_PASSWORD_ENDPOINT, {state: {type: data?.type}})
            // }
            if(result?.data?.is_password_set){
                data.navigate(ENTER_PASSWORD_ENDPOINT, {state: {type: data?.type}})
            }else{
                if(!result?.data?.is_otp_verified){
                    data.navigate(VERIFICATION_ENDPOINT, {state: {type: data?.type}})
                    notify('success', result.message)
                }else{
                    data.navigate(CREATE_PASSWORD_ENDPOINT, {state: {type: data?.type}})
                }
            }
        }else{
            yield put({ type: LOGIN_FAILURE, message: result.message });
        }        
        yield put({ type: LOGIN_REQUEST_END });
    }catch(error){
        logger.log(error)
        yield put({ type: LOGIN_FAILURE, message: error.message });
    }
}


function* loginSaga(){
    yield takeEvery(LOGIN, login)
}

export default loginSaga;