import * as Yup from "yup";
import "yup-phone-lite";

export const prelimAssessmentValidation = (countryCode)=>{

    return Yup.object({
        user_height: Yup.string().required("*Please enter height"),
        user_weight: Yup.string().required("*Please enter weight"),
        objectives: Yup.string().required("*Please enter objectives"),

        is_current_health_complaint: Yup.string().required("*Please select current health complain"),
        health_complaint_keywords: Yup.array()
        .when("is_current_health_complaint", {
            is: is_current_health_complaint => is_current_health_complaint === '1',
            then: ()=> Yup.array()
              .of(
                Yup.object().shape({
                  health_category: Yup.string().required("*Required"),
                  health_condition: Yup.string(),
                  details: Yup.string().required("*Required"),
                  intensity: Yup.string().nullable(),
                  duration: Yup.string(),
                })
              ).min(1, "This field is required."),
            otherwise:  ()=> Yup.array()
              .of(
                Yup.object().shape({
                  health_category: Yup.string(),
                  health_condition: Yup.string(),
                  details: Yup.string(),
                  intensity: Yup.string().nullable(),
                  duration: Yup.string(),
                })
              )
            })
        ,
        is_past_medical_history: Yup.string().required("*Please enter past medical status"),
        past_medical_history: Yup.array()
        .when("is_past_medical_history", {
            is: is_past_medical_history => is_past_medical_history === '1',
            then: ()=> Yup.array()
              .of(
                Yup.object().shape({
                  condition: Yup.string().required("*Required"),
                  period: Yup.string(),
                })
              ).min(1, "This field is required."),
            otherwise:  ()=> Yup.array()
              .of(
                Yup.object().shape({
                  condition: Yup.string(),
                  period: Yup.string(),
                })
              )
            })
        ,

        is_current_medications: Yup.string().required("*Please current medication status"),
        current_medications: Yup.string().when("is_current_medications", {
            is: is_current_medications => is_current_medications === '1',
            then: ()=> Yup.string().required("This field is required."),
            otherwise:  ()=> Yup.string()
          }),

        is_allergies: Yup.string().required("*Please enter allergies status."),
        allergies_keywords: Yup.string().when("is_allergies", {
            is: is_allergies => is_allergies === '1',
            then: ()=> Yup.string().required("This field is required."),
            otherwise:  ()=> Yup.string()
          }),

        family_history: Yup.string().required("*Please enter family history."),

        diet: Yup.string().required("*Required"),
        recommended_stay_duration: Yup.string(),
        recommended_program: Yup.string(),
        medical_reports_requested_for: Yup.string(),
        specific_request_from_guest: Yup.string()
    })
} 